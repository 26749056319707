<div id="higher-ed" class="full-page pad-content"
                style="background-color: transparent;color:black;height:fit-content;">
    <div class="background-image-bible page-layout">
    <div class="link-box">
        <a href="" class="text-outline-white link-button outlined-text-blue;" style="color:darkblue">Main Page</a>
        <a href="../#/what-we-believe" class="text-outline-white link-button outlined-text-blue;" style="color:darkblue">What We Believe</a>
        <a href="../#/join-with-us" class="text-outline-white link-button outlined-text-blue;" style="color:darkblue">Join With Us</a>
    </div>
    <div class="text-outline-blue intro-title-grid title-grid-layout">
        <div style="display:flex;flex-direction: column;grid-column:5/span 15;grid-row:1/span 10;">
            <div style="border: transparent 2px solid;width:100%;font-size:calc(1em + 2vh + 2vw);">Higher Education</div>
        </div>
    </div>
    <div class="bubble">
        <div class="black-text">
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer sodales lectus non egestas finibus. 
                Morbi semper bibendum nibh vitae lacinia. Suspendisse non mi et enim dignissim mollis. Suspendisse 
                pretium, nulla et laoreet porttitor, ipsum arcu feugiat ipsum, nec commodo sem arcu ac odio. Ut 
                finibus lorem ligula, feugiat pretium neque vehicula quis. Sed porta aliquam sem, vitae condimentum 
                diam volutpat ut. Donec at magna porttitor, pretium urna vel, aliquet lectus. Morbi congue ultrices 
                turpis, eget sagittis augue cursus vitae. Nulla eu pretium eros. Vivamus tempus vel mauris malesuada 
                sollicitudin. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; 
                Proin at urna ut ligula pharetra elementum sed nec mauris. Nunc pharetra, tellus a gravida sollicitudin, 
                augue augue tincidunt nisi, id hendrerit ligula eros finibus libero. Suspendisse elementum erat eu 
                suscipit fermentum. Mauris sapien enim, lobortis vel vulputate id, efficitur eu orci.</p>
        </div>
    </div>
</div>
<footer class="pad-content footer-color-dark-blue"></footer>
