<div id="meaning" class="full-page pad-content" 
                       style="background-color: transparent;color:black;">
    <div class="background-image-bible page-layout">
        <div class="link-box">
            <a href="" class="text-outline-white link-button outlined-text-brown;" style="color:black">Main Page</a>
            <a href="../#/who-are-we" class="text-outline-white link-button outlined-text-brown;" style="color:black">Who We Are</a>
            <a href="../#/what-we-believe" class="text-outline-white link-button outlined-text-brown;" style="color:black">What We Believe</a>
        </div>

        <div class="text-outline-blue intro-title-grid title-grid-layout">
            <div style="display:flex;flex-direction: column;grid-column:5/span 15;grid-row:1/span 10;">
                <div style="border: transparent 2px solid;width:100%;font-size:1.6em;">What We Believe About</div>
                <div style="border: transparent 2px solid;width:100%;font-size:5.0em;">Life's Meaning</div>
            </div>
        </div>
        <div class="bubble-light">
            <p class="dark-subheader">Why are we here, anyway?</p>
            <div class="black-text">
                <p>
                    The Westminster Shorter Catechism states:
                </p>
                <p class="blockquote text-size">
                    <i>What is the chief end of man? </i><br>Man's chief end is to glorify God, and to enjoy Him forever.
                </p>
                <p>The Bible says:</p>
                <div class="biblequote poem">
                    <span>In Your presence is fullness of joy;</span><br>
                    <span>At Your right hand are pleasures forevermore.</span><br>
                    &mdash;<a target="_blank" rel="noopener" href="https://www.biblegateway.com/passage/?search=Psalm+16%3A11&version=NKJV">Psalm 16:11 (NKJV)</a>
                </div><br>
                <div class="biblequote poem">
                    <span>And my soul shall be joyful in the </span><span style="font-variant:small-caps;">Lord;</span><br>
                    <span>It shall rejoice in His salvation.</span><br>
                    &mdash;<a target="_blank" rel="noopener" href="https://www.biblegateway.com/passage/?search=Psalm%2035%3A9&version=NKJV">Psalm 35:9</a>
                </div><br>
                <div class="biblequote poem">
                    <span>My soul shall be satisfied as with marrow and fatness,</span><br>
                    <span>And my mouth shall praise You with joyful lips.</span><br>
                    &mdash;<a target="_blank" rel="noopener" href="https://www.biblegateway.com/passage/?search=Psalm%2063%3A5&version=NKJV">Psalm 63:5</a>
                </div><br>
                <div class="biblequote poem">
                    <span>These things I have spoken to you, that My joy may remain in you, and that your joy may be full.</span><br>
                    &mdash;<a target="_blank" rel="noopener" href="https://www.biblegateway.com/passage/?search=John%2015%3A11&version=NKJV">John 15:11</a>
                </div><br>
                <p>
                    Im&shy;a&shy;gine that! We can spend for&shy;ever and ever in a 
                    glor&shy;ious cy&shy;cle of love in which we make God 
                    a&shy;maz&shy;ing&shy;ly happy, and God makes us 
                    a&shy;maz&shy;ing&shy;ly hap&shy;py in return.
                </p>
                <p>And that for&shy;ev&shy;er can be&shy;gin <i>now.</i></p>
                <p>
                    Your life can have a meaning exalted above the grinding of this world. 
                    Your life can be changed in ways you can't begin to imagine. Your life 
                    can have a purpose with results that will shine for all of eternity.
                </p>
                <p>
                    Get in touch with us. Let us show you how you can have love, 
                    joy, and peace in a life that lifts you up to God Himself.
                </p>
            </div>
        </div>
    </div> 
</div>
<footer class="pad-content footer-color-dark-blue footer-width" style="margin-top:40px;"></footer>
