import { Component } from '@angular/core';

@Component({
  selector: 'app-join-with-us',
  templateUrl: './join-with-us.component.html',
  styleUrls: ['./join-with-us.component.css']
})
export class JoinWithUsComponent {

}
