<div id="jesus" class="full-page pad-content" 
                    style="background-color: transparent;color:black;height:fit-content;">
        <div class="background-image-bible page-layout">
        <div class="link-box">
            <a href="" class="text-outline-white link-button outlined-text-blue;" style="color:darkblue">Main Page</a>
            <a href="../#/what-we-believe" class="text-outline-white link-button outlined-text-blue;" style="color:darkblue">What We Believe</a>
            <a href="../#/join-with-us" class="text-outline-white link-button outlined-text-blue;" style="color:darkblue">Join With Us</a>
        </div>

        <div class="text-outline-blue intro-title-grid title-grid-layout">
            <div style="display:flex;flex-direction: column;grid-column:5/span 15;grid-row:1/span 10;">
                <div style="border: transparent 2px solid;width:100%;font-size:1.6em;">What We Believe About</div>
                <div style="border: transparent 2px solid;width:100%;font-size:5.0em;">Jesus Christ</div>
            </div>
        </div>

        <div>        
            <div class="bubble-light">
                <p class="jesus-subheader">Who is Jesus?</p>
                <p class="jesus-text">
                    <strong>Jesus Christ is God Him&shy;self.</strong> There's a lot more about this that 
                    should be said, but that is the start&shy;ing point of it all. If you un&shy;der&shy;stand 
                    that, great! God stands ready to give you <i>real faith</i> (and maybe you have al&shy;ready 
                    re&shy;ceived this am&shy;az&shy;ing gift!) If you don't have it, you need to read on.
                </p>
                <p class="jesus-text">
                    C.S. Lewis, the auth&shy;or of <i>The Chron&shy;icles of Nar&shy;nia</i>, once wrote, 
                </p>
                <div class="blockquote text-size">
                    I am try&shy;ing here to pre&shy;vent any&shy;one say&shy;ing the real&shy;ly 
                    fool&shy;ish thing that peo&shy;ple often say about Him: I'm ready to ac&shy;cept 
                    Jesus as a great mor&shy;al teach&shy;er, but I don't ac&shy;cept his claim 
                    to be God.  That is the one thing we must not say.  A man who was mere&shy;ly 
                    a man and said the sort of things Jesus said would not be a great mor&shy;al 
                    teach&shy;er.  He would eith&shy;er be a lun&shy;a&shy;tic&mdash;on the lev&shy;el 
                    with the man who says he is a poached egg&mdash;or else he would be the Dev&shy;il 
                    of Hell.  You must make your choice.  Eith&shy;er this man was, and is, the Son 
                    of God, or else a mad&shy;man or some&shy;thing worse.  You can shut him up for 
                    a fool, you can spit at him and kill him as a de&shy;mon or you can fall at his 
                    feet and call him Lord and God, but let us not come with any pa&shy;tron&shy;iz&shy;ing 
                    non&shy;sense about his being a great human teach&shy;er.  He has not left that 
                    open to us.  He did not in&shy;tend to.
                </div>
                <p class="jesus-text">
                    <strong>Jesus, the Man born in Beth&shy;le&shy;hem</strong> two thou&shy;sand 
                    years ago, who lived a sin&shy;less life and taught in anc&shy;ient Is&shy;rael 
                    for three years, is the Son of God.  That means He is the God the Son, 
                    the sec&shy;ond Per&shy;son of the Tri&shy;une God, fused in&shy;to 
                    hu&shy;man&shy;i&shy;ty and born of the vir&shy;gin Mary.  He came to make 
                    it pos&shy;si&shy;ble for us sin&shy;ful, God-re&shy;ject&shy;ing peo&shy;ple 
                    to be changed&mdash;born spir&shy;it&shy;ual&shy;ly into a new, clean, 
                    e&shy;ter&shy;nal life. When He died on the cross at Jeru&shy;salem, He 
                    took upon Him&shy;self the aw&shy;ful, ter&shy;ri&shy;fy&shy;ing 
                    pun&shy;ish&shy;ment that we de&shy;serve, so that when any&shy;one 
                    comes to Him and asks Him to res&shy;cue us from hell, he sub&shy;sti&shy;tutes 
                    his own death for ours. He pays the pen&shy;al&shy;ty for us. He makes us fit to spend a 
                    beaut&shy;i&shy;ful, glor&shy;i&shy;ous e&shy;ter&shy;ni&shy;ty with Him.
                </p>
                <p class="jesus-text">
                    <strong>How do we know that Jesus really is the Son of God?</strong> <br>
                    <strong>First, He act&shy;ual&shy;ly claimed to be the Son of God. </strong> 
                    <a class="jesus-link" target="_blank"  rel="noopener" href="https://www.biblegateway.com/passage/?search=John%2010%3A22-42&version=NKJV">John 10:24</a> begins,</p>
                <div class="blockquote text-size biblequote">
                    Then the Jews sur&shy;round&shy;ed Him and said to Him, “How long do You 
                    keep us in doubt? If You are the Christ, tell us plain&shy;ly.” Jesus 
                    ans&shy;wer&shy;ed them, “I told you, and you do not be&shy;lieve. 
                    The works that I do in My Fa&shy;ther’s name, they bear wit&shy;ness 
                    of Me. But you do not be&shy;lieve, be&shy;cause you are not of My sheep, 
                    as I said to you. My sheep hear My voice, and I know them, and they 
                    fol&shy;low Me. And I give them e&shy;ter&shy;nal life, and they shall 
                    never per&shy;ish; neith&shy;er shall any&shy;one snatch them out 
                    of My hand. My Fa&shy;ther, who has given them to Me, is great&shy;er 
                    than all; and no one is able to snatch them out of My Fa&shy;ther’s 
                    hand. <span class="highlight"><i>I and My Father are one.</i></span>”</div>
                <p class="jesus-text">A little farther down, the Scripture reads,</p>
                <div class="blockquote text-size biblequote">
                    Then the Jews took up stones again to stone Him. Jesus ans&shy;wer&shy;ed 
                    them, “Many good works I have shown you from My Fa&shy;ther. For which of 
                    those works do you stone Me?” The Jews ans&shy;wer&shy;ed Him, saying, 
                    “For a good work we do not stone You, but for blas&shy;phem&shy;y, 
                    and be&shy;cause You, being a Man, make Your&shy;self God.” Jesus 
                    ans&shy;wer&shy;ed them, “Is it not writ&shy;ten in your law, ‘I said, 
                    “You are gods” ’? If He called them gods, to whom the word of God 
                    came (and the Scrip&shy;ture can&shy;not be bro&shy;ken), do you say 
                    of Him whom the Father sanct&shy;i&shy;fied and sent into the world, 
                    ‘You are blas&shy;pheming,’ <span class="highlight"><i>because I 
                    said, ‘I am the Son of God’</i></span>? If I do not do the works 
                    of My Fa&shy;ther, do not be&shy;lieve Me; but if I do, though you 
                    do not be&shy;lieve Me, be&shy;lieve the works, that you may know 
                    and be&shy;lieve that the Fa&shy;ther is in Me, and I in Him.”</div>
                <p class="jesus-text">
                    Those around Him rec&shy;og&shy;nized that He was mak&shy;ing that 
                    bold claim, and they hat&shy;ed Him for it. And He did&shy;n't deny 
                    it&mdash;He stood firm in mak&shy;ing that claim. And there are many 
                    other pla&shy;ces in the Gos&shy;pels where He as&shy;sert&shy;ed his 
                    auth&shy;ent&shy;ic claim to be the Son of God.
                </p>
                <p class="jesus-text">
                    <strong>Sec&shy;ond, He proved it again and again by migh&shy;ty 
                    mir&shy;a&shy;cles</strong> like heal&shy;ing the sick, re&shy;stor&shy;ing 
                    sight to the blind, giv&shy;ing hear&shy;ing to the deaf, mak&shy;ing 
                    the lame walk, cast&shy;ing out de&shy;mons, raising the dead. Over and over 
                    again Jesus demonstrated his awesome power, leaving those who followed 
                    Him with no doubt&mdash;this Man Jesus was truly God in the flesh. 
                    There were so many mir&shy;a&shy;cles that 
                    <a class="jesus-link" target="_blank"  rel="noopener" href="https://www.biblegateway.com/passage/?search=John%2020%3A24-31&version=NKJV">the Gospel writer declares</a>, 
                    <span style="color:darkblue;">
                        "And tru&shy;ly Jesus did many oth&shy;er signs in the pre&shy;sence of His dis&shy;ci&shy;ples, 
                        which are not writ&shy;ten in this book; but these are writ&shy;ten that you may 
                        be&shy;lieve that Jesus is the Christ, the Son of God, and that be&shy;liev&shy;ing 
                        you may have life in His name."
                    </span>
                </p>
                <p class="jesus-text">
                    <strong>Third, He raised Him&shy;self from death</strong> after his 
                    cru&shy;ci&shy;fix&shy;ion to prove His div&shy;ine pow&shy;er and His 
                    right to save us from hell. The first clue is that Jesus claim&shy;ed 
                    the pow&shy;er to raise Him&shy;self from the dead. In John 10:17-18, 
                    Jesus says, "<span class="biblequote">There&shy;fore My Fa&shy;ther 
                    loves Me, be&shy;cause I lay down My life that I may take it again. 
                    No one takes it from Me, but I lay it down of My&shy;self. I have 
                    pow&shy;er to lay it down, </span><span style="color:red"><i>and 
                    I have pow&shy;er to take it again</i>.</span>" Jesus, being God 
                    in the flesh, has all pow&shy;er of life and death at his 
                    com&shy;mand. That's why Jesus was able to rise from the dead:
                </p>
                <div class="blockquote text-size biblequote">
                    Now the first day of the week Mary Mag&shy;dal&shy;ene went to 
                    the tomb ear&shy;ly, while it was still dark, and saw that the 
                    stone had been tak&shy;en away from the tomb. Then she ran and 
                    came to Simon Peter, and to the other dis&shy;ci&shy;ple, whom 
                    Jesus loved, and said to them, “They have tak&shy;en away the 
                    Lord out of the tomb, and we do not know where they have laid 
                    Him.” Peter there&shy;fore went out, and the other dis&shy;ci&shy;ple, 
                    and were go&shy;ing to the tomb. So they both ran to&shy;ge&shy;ther, 
                    and the oth&shy;er dis&shy;ci&shy;ple out&shy;ran Peter and 
                    came to the tomb first. And he, stoop&shy;ing down and look&shy;ing 
                    in, saw the lin&shy;en cloths ly&shy;ing there; yet he did not 
                    go in. Then Simon Peter came, fol&shy;low&shy;ing him, and went 
                    into the tomb; and he saw the lin&shy;en cloths ly&shy;ing there, 
                    and the hand&shy;ker&shy;chief that had been a&shy;round His head, 
                    not ly&shy;ing with the lin&shy;en cloths, but fold&shy;ed 
                    to&shy;ge&shy;ther in a place by it&shy;self. Then the other 
                    dis&shy;ci&shy;ple, who came to the tomb first, went in also; 
                    and he saw and be&shy;lieved. For as yet they did not know 
                    the Scrip&shy;ture, that He must rise again from the dead. 
                    Then the dis&shy;ci&shy;ples went away a&shy;gain to their 
                    own homes. 
                    <br/><br/>
                    But Mary stood out&shy;side by the tomb weep&shy;ing, and 
                    as she wept she stooped down and looked into the tomb. And 
                    she saw two ang&shy;els in white sit&shy;ting, one at the head 
                    and the oth&shy;er at the feet, where the body of Jesus had lain. 
                    Then they said to her, “Wom&shy;an, why are you weep&shy;ing?” 
                    She said to them, “Be&shy;cause they have tak&shy;en a&shy;way 
                    my Lord, and I do not know where they have laid Him.” Now when 
                    she had said this, she turned a&shy;round and saw Jesus 
                    stand&shy;ing there, and did not know that it was Jesus. Jesus 
                    said to her, “Wom&shy;an, why are you weep&shy;ing? Whom are 
                    you seek&shy;ing?” She, sup&shy;pos&shy;ing Him to be the 
                    gar&shy;den&shy;er, said to Him, “Sir, if You have car&shy;ried 
                    Him a&shy;way, tell me where You have laid Him, and I will 
                    take Him away.” Jesus said to her, “Mary!” She turned and said 
                    to Him, “Rab&shy;boni!” (which is to say, Teach&shy;er). 
                    Jesus said to her, “Do not cling to Me, for I have not yet 
                    as&shy;cend&shy;ed to My Fa&shy;ther; but go to My breth&shy;ren 
                    and say to them, ‘I am as&shy;cend&shy;ing to My Fa&shy;ther 
                    and your Fa&shy;ther, and to My God and your God.’” Mary 
                    Mag&shy;dal&shy;ene came and told the dis&shy;ci&shy;ples 
                    that she had seen the Lord, and that He had spok&shy;en these 
                    things to her. (<a class="jesus-link" target="_blank"  rel="noopener" 
                    href="https://www.biblegateway.com/passage/?search=John+20%3A+1-18&version=NKJV">John 20:1-18</a>)       
                </div>
                <br>
                <p class="jesus-subheader">What does this mean?</p>
                <p class="jesus-text">
                    The death and resurrection of the Lord Jesus Christ made it 
                    possible for God to <a target="_blank" rel="noopener" 
                    href="https://www.biblegateway.com/passage/?search=Romans+5%3A6-11&version=NKJV">take away all your sin</a> and 
                    <a target="_blank" rel="noopener" href="https://www.biblegateway.com/passage/?search=2+Corinthians+5%3A17&version=NKJV">create a new, 
                    perfect creature within you</a>. <a target="_blank" rel="noopener" 
                    href="https://www.biblegateway.com/passage/?search=john+8%3A36&version=NKJV">You can be truly free</a> 
                    from your life of self-destruction, and you can escape the <a target="_blank" rel="noopener" 
                    href="https://www.biblegateway.com/passage/?search=Luke+16%3A23-26&version=NKJV">eternal agony that waits 
                    for you</a>.  
                </p>
                <p class="jesus-text">
                    <strong>Jesus is the Christ, the Messiah, the one and only Son of God. He wants to 
                    show you how much you need Him, and save you from yourself and from eternal 
                    punishment. Believe Him. And get a hold of us&mdash;we can explain to you 
                    how to find that amazing freedom in Christ.</strong>
                </p>
            </div>
        </div>
        <br><br>
    </div> 
</div>
<footer class="pad-content footer-color-dark-blue" style="width:97vw;margin-top:-20px;"></footer>
