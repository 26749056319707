import { Component } from '@angular/core';

@Component({
  selector: 'app-higher-ed',
  templateUrl: './higher-ed.component.html',
  styleUrl: './higher-ed.component.css'
})
export class HigherEdComponent {

}
