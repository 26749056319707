
<menu-bar class="link-box" style="color:brown;font-kerning:auto;letter-spacing:0.01em;" [menuItems]="menuItems"></menu-bar>
<div id="what-to-expect" class="full-page pad-content"
                         style="color:black;height:fit-content;">
    <div class="background-image-church-family page-layout image">
        <div id="top"></div>
        <div class="intro-title-grid title-grid-layout">
            <div class="text-outline-brown" style="margin:20px;display:flex;flex-direction:column;grid-column:5/span 15;grid-row:1/span 10;">
                <div class="title">What to Expect</div>
            </div>
            <div class="bubble-dark text-outline-brown">
                <p class="subheader">Thanks for stopping by!</p>
                <p class="text text-size" style="margin-bottom:20px;">
                    Here's some quick&mdash;and hopefully useful&mdash; information. 
                    We'd love for you to be a part of our community this Sunday!
                </p>
                <p class="subheader">When do we meet?</p>
                <p class="text text-size"><strong>Every Sunday</strong></p>
                <p class="text text-size">9:30 a.m. Worship Service</p>
                <p class="text text-size">8:30 a.m. Sunday School and Bible Study (except for the summer)</p>
                <p class="text text-size">10:40 a.m. Choir Practice after worship</p>
                <p class="text text-size">
                    On the third Sunday of the month we have a fellowship time 
                    in the fellowship hall with snacks.</p>
                <p class="text text-size" style="margin-bottom:20px;">
                    Throughout the month, we have various Bible studies and meetings 
                    with the men, women, and youth groups.</p>
                <p class="subheader">What time should I arrive?</p>
                <p class="text text-size" style="margin-bottom:20px;">
                    The service starts at 9:30 a.m. Arriving at 9:15 usually 
                    gives you time to grab a bulletin, find some friendly faces, 
                    and settle in before everything begins.
                </p>
                <p class="subheader">What is the service like?</p>
                <p class="text text-size">
                    We keep a relatively consistent worship format. Below is just a snapshot 
                    of our Sunday; If you're unfamiliar with certain words or concepts, don't 
                    hesitate to ask (it can even be anonymous if you want)! 
                </p>
                <ul class="text text-size" style="margin-left:10vw;">
                    <li>Greeting from Pastor Dan</li>
                    <li>Song of praise (we like the Gloria Patri)</li>
                    <li>Our faith confession</li>
                    <li>Worship song (sometimes traditional, sometimes contemporary)</li>
                    <li>Bible reading</li>
                    <li>Pastor's prayer, followed by the Lord's Prayer</li>
                    <li>Worship song</li>
                    <li>Bible reading</li>
                    <li>Bible-based sermon (about 20 minutes)</li>
                    <li>Worship song/offering collection</li>
                    <li>Closing statement from Pastor Dan</li>
                    <li>Closing song</li>
                    <li>Short time of reflection/silent prayer</li>
                </ul>
                <p class="subheader">When will the service finish?</p>
                <p class="text text-size">10:45 a.m. at the latest.</p>
                <p class="subheader">Can I leave during the service if I feel uncomfortable?</p>
                <p class="text text-size" style="margin-bottom:20px;">If you feel the need, you can leave at any time.</p>
                <p class="subheader">Will I be the odd one out?</p>
                <p class="text text-size" style="margin-bottom:20px;">
                    Nope, there are people at St. Paul's from many different walks of life.  We make an 
                    effort to welcome, be gracious, and show kindness to everyone.  It's our personal 
                    mission to reflect the love God has shown us.</p>
                <p class="subheader">Can I bring my children?</p>
                <p class="text text-size" style="margin-bottom:20px;">
                    Absolutely, children are a huge part of the church. We have 
                    Sunday school and youth group for just this purpose.
                </p>
                <p class="subheader">Can I come if I'm not a Christian&mdash;or even not a good person?</p>
                <p class="text text-size" style="margin-bottom:20px;">
                    Please do! The church is made up of imperfect people who know they need Jesus's 
                    saving grace in their lives. It would be strange if any of us were flawless.
                </p>
                <p class="subheader">Will I be expected to do anything or give any money?</p>
                <p class="text text-size" style="margin-bottom:20px;">
                    Nope, you won't be bored, embarrassed, or asked for money. We take an offering, 
                    but it's for people committed to the St. Paul's community, and visitors aren't 
                    expected to give. On the contrary, you will be warmly welcomed, encouraged in your 
                    walk with God&mdash;and we hope you'll come back to be with us again.
                </p>
            </div>
        </div>
    </div>
</div>
<footer class="pad-content footer-color-brown" style="margin:-20px 20px 0 20px;"></footer>
