<menu-bar class="link-box" style="color:brown;width:100%;word-wrap:break-word;" [menuItems]="menuItems"></menu-bar>
<div id="tithing" class="full-page pad-content background-image-church-family-sticky" 
                     style="background-color: transparent;color:black;height:100vh;">
    <div class="page-layout">
        <div class="text-outline-brown title-grid-layout">
            <div style="display:flex;flex-direction: column;grid-column:5/span 15;grid-row:1/span 10;">
                <div class="page-title">Member Giving</div>
            </div>
        </div>
        <div class="text-outline-brown">
            <div class="bubble-dark">
                <div class="white-subheader">Online Giving Through Tithely</div>
                <div class="text-outline-brown" style="color:white;margin: 20px;font-size:calc(0.1em + 1vw + 1vh)" >
                    If you have a Tithely account, you can log in here and give your tithes and offerings. 
                    If you've never signed up with Tithely, <a class="white-link" href="https://signup.tithe.ly/">click here to go to the signup page.</a> You 
                    can also download the app for your Android phone or iPhone.
                </div>
                <br>
                <div style="display:flex;flex-direction:row;justify-content:center;width:100%;">
                    <div [class.hidden]="!isLoading">
                        <img src="../../assets/img/spinner.gif" alt="Loading..."/>
                    </div>
                    <iframe #iframe (load)="onIframeLoad()" [class.hidden]="isLoading" class="tithely-iframe" [src]="url | safe" title="Online Member Giving"></iframe>
                </div>   
                <br>
            </div>
            <br>
        </div>
    </div>
</div>
<div style="z-index:1000;margin-top:-10px;margin-left:0;margin-right:-20px;width:100%;">
    <footer class="footer-color-brown footer-width" style="width:100%"></footer>
</div>
