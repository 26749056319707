<div id="faith" class="full-page pad-content"
                style="background-color: transparent;color:black;height:fit-content;">
    <div class="background-image-bible page-layout">
    <div class="link-box">
        <a href="" class="text-outline-white link-button outlined-text-blue;" style="color:darkblue">Main Page</a>
        <a href="../#/what-we-believe" class="text-outline-white link-button outlined-text-blue;" style="color:darkblue">What We Believe</a>
        <a href="../#/join-with-us" class="text-outline-white link-button outlined-text-blue;" style="color:darkblue">Join With Us</a>
    </div>
    <div class="text-outline-blue intro-title-grid title-grid-layout">
        <div style="display:flex;flex-direction: column;grid-column:5/span 15;grid-row:1/span 10;">
            <div style="border: transparent 2px solid;width:100%;font-size:1.6em;">What We Believe About</div>
            <div style="border: transparent 2px solid;width:100%;font-size:5.0em;">Faith</div>
        </div>
    </div>
    <div class="bubble-light">
        <div class="dark-text">
            <p class="dark-subheader">What is faith?</p>
            <p>
                <strong>Let's start</strong> by describing what it <i>isn't</i>. People talk about 
                faith being a set of teach&shy;ings, or a mere&shy;ly hu&shy;man be&shy;lief in 
                some&shy;thing or some&shy;one. But that's not what we're talk&shy;ing about here. 
                Auth&shy;entic faith in God is not get&shy;ting your&shy;self psyched up over 
                some&shy;thing. It isn't mere&shy;ly a set of abs&shy;tract re&shy;lig&shy;ious 
                be&shy;liefs to which you give some int&shy;el&shy;lect&shy;ual a&shy;gree&shy;ment, 
                eith&shy;er. 
            </p>
            <p class="dark-subheader">You can't do it yourself</p>
            <p>
                <strong>Just ac&shy;cept&shy;ing</strong> a doc&shy;trine or dog&shy;ma, how&shy;ever valid, 
                isn't auth&shy;entic faith. Many of us who have grown up in church&shy;es oft&shy;en 
                take some teach&shy;ing to be true. And e&shy;ven those who nev&shy;er went to church 
                may have some opin&shy;ion that is con&shy;sist&shy;ent with the teach&shy;ing of Scrip&shy;ture. 
                Now right op&shy;in&shy;ions and ac&shy;cept&shy;ance of sound doc&shy;trine may point us in 
                the right di&shy;rec&shy;tion, but that simply does not go far e&shy;nough.
            </p>
            <p>
                And hav&shy;ing emo&shy;tion&shy;al ex&shy;per&shy;i&shy;en&shy;ces isn't what real faith is about. A per&shy;son to 
                whom God has given real faith may have mo&shy;ments of high emo&shy;tion&mdash;what 
                we some&shy;times call "mount&shy;ain&shy;top" ex&shy;per&shy;i&shy;en&shy;ces&mdash;but e&shy;mo&shy;tions can fool you.
                You may be ut&shy;ter&shy;ly lost and with&shy;out God in the world, while enjoy&shy;ing hap&shy;pi&shy;ness 
                and think&shy;ing that ev&shy;er&shy;y&shy;thing's just fine, right up un&shy;til the day you die.
            </p>
            <p> 
                <i>This is where God has to come in.</i>            
            </p>

            <p class="dark-subheader">The real thing</p>
            <p>
                <strong>Real faith</strong>&mdash;the faith that saves you from e&shy;ter&shy;nal 
                ruin&mdash;is the pro&shy;found su&shy;per&shy;nat&shy;ur&shy;al con&shy;fid&shy;ence 
                that God is real. 
                <a class="dark-link" target="_blank" rel="noopener" 
                    href="https://www.biblegateway.com/passage/?search=hebrews+11%3A1&version=NKJV">Hebrews 11:1</a> says, 
                "<span class="biblequote">Now faith is the sub&shy;stance of things 
                hoped for, the evi&shy;dence of things not seen.</span>" In other  
                words, real faith&mdash;the faith that God offers you as a gift&mdash;is 
                the con&shy;firm&shy;ation of the things that God pro&shy;mises to those 
                who will open them&shy;selves up to Him. 
            </p> 
            <p>    
                Faith is the proof of the things we can't yet see. Faith 
                per&shy;ceives the real&shy;ity of the things that aren't 
                re&shy;vealed to the physical senses. And be&shy;cause 
                this is a su&shy;per&shy;nat&shy;ural con&shy;fid&shy;ence, 
                this faith is some&shy;thing that God Him&shy;self must 
                give you. You can't just decide to agree with it and make 
                it so. As the great Bible scholar John Cal&shy;vin wrote, 
                "[T]he know&shy;ledge of faith con&shy;sists more of 
                cert&shy;ain&shy;ty than dis&shy;cern&shy;ment." It 
                isn't some&shy;thing you can fig&shy;ure out. It is too big 
                for your int&shy;el&shy;lect, your soul, your heart.
            </p>
            <p>    
                You see, it is God that has to do the in&shy;it&shy;ia&shy;ting of your 
                faith, be&shy;cause you are ir&shy;rep&shy;ar&shy;a&shy;bly bro&shy;ken. 
                Your ab&shy;i&shy;li&shy;ty to per&shy;ceive God was de&shy;stroy&shy;ed 
                from the mo&shy;ment you came to be. And God knows&mdash;has known from 
                e&shy;ter&shy;nity past&mdash;who will be&shy;lieve and who won't. If 
                you are read&shy;ing this now, God may be call&shy;ing to you to come to Him. 
            </p>
            <p>
                Do you want to seek God? If you do, 
                don't stop here. Get a Bible, or go online to find it. Start reading it&mdash;the 
                <a class="dark-link" target="_blank" rel="noopener" 
                    href="https://www.biblegateway.com/passage/?search=John%201&version=NKJV">Gos&shy;pel 
                of John</a> is a good place to start. And then get a hold of us. We'd love to en&shy;cour&shy;age 
                you in your seek&shy;ing after God.
            </p>
        </div>
    </div>
</div>
<footer class="pad-content" style="width:97vw;"></footer>
