<div id="the-bible" class="full-page pad-content" 
                    style="background-color: transparent;color:black;height:fit-content;">
        <div class="background-image-bible page-layout">
        <div class="link-box">
            <a href="" class="text-outline-white link-button outlined-text-blue;" style="color:darkblue">Main Page</a>
            <a href="../#/what-we-believe" class="text-outline-white link-button outlined-text-blue;" style="color:darkblue">What We Believe</a>
            <a href="../#/join-with-us" class="text-outline-white link-button outlined-text-blue;" style="color:darkblue">Join With Us</a>
        </div>

        <div class="text-outline-blue intro-title-grid title-grid-layout">
            <div style="display:flex;flex-direction: column;grid-column:5/span 15;grid-row:1/span 10;">
                <div style="border: transparent 2px solid;width:100%;font-size:1.6em;">What We Believe About</div>
                <div style="border: transparent 2px solid;width:100%;font-size:5.0em;">The Bible</div>
            </div>
        </div>

        <div>        
            <div class="bubble-light">
                <p class="bible-subheader">Is this book really from God?</p>
                <div class="bible-text">
                    <p>
                        <strong>Throughout the centuries</strong>, Christians have held that the Bible is God's 
                        revealed Word, given through proph&shy;ets and apostles so that human&shy;ity can come to know 
                        God person&shy;ally and re&shy;ceive eter&shy;nal life. We at St. Paul's Church em&shy;brace this truth 
                        whole&shy;heart&shy;edly, and we teach it as the one and only source of truth for life and hope 
                        for all people every&shy;where. We encour&shy;age our members to read it every day, and we get 
                        together often to study the Bible to deepen our faith and our under&shy;stand&shy;ing of who God is. 
                        One of the great creeds of the Church, the Belgic Con&shy;fes&shy;sion, says,
                    </p>
                    <div class="blockquote text-size" style="margin-bottom:20px;">
                        We con&shy;fess that this Word of God was not sent nor de&shy;liv&shy;ered by the will of man, but 
                        that men spake from God, being moved by the Holy Spir&shy;it, as the apo&shy;stle Peter says; 
                        and that af&shy;ter&shy;wards God, from a spe&shy;cial care which He has for us and our sal&shy;va&shy;tion, 
                        com&shy;mand&shy;ed His ser&shy;vants, the proph&shy;ets and a&shy;post&shy;les, to com&shy;mit His re&shy;vealed word to 
                        writing; and He Him&shy;self wrote with His own fing&shy;er the two tab&shy;les of the law. 
                        There&shy;fore we call such writ&shy;ings holy and div&shy;ine Scrip&shy;tures.
                    </div>
                    <p>
                        <strong>This is the core of what we believe about the Bible:</strong><a target="_blank" rel="noopener" href="https://rcus.org/doctrine/">*</a>
                    </p>
                    <ol>
                        <li>God, who is Him&shy;self Truth and speaks truth only, has in&shy;spired Holy Scrip&shy;ture in 
                            order thereby to reveal Him&shy;self to lost man&shy;kind through Jesus Christ as Cre&shy;at&shy;or 
                            and Lord, Re&shy;deem&shy;er and Judge. Holy Scrip&shy;ture is God’s wit&shy;ness to Him&shy;self.</li>
                        <li>Holy Scrip&shy;ture, being God’s own Word, written by men pre&shy;pared and super&shy;intend&shy;ed 
                            by His Spirit, is of in&shy;fal&shy;lible divine auth&shy;ority in all mat&shy;ters upon 
                            which it touch&shy;es: It is to be be&shy;lieved, as God’s in&shy;struc&shy;tion, in all 
                            that it af&shy;firms; obey&shy;ed, as God’s command, in all that it re&shy;quires; em&shy;braced, 
                            as God’s pledge, in all that it pro&shy;mis&shy;es.</li>
                        <li>The Holy Spirit, Scrip&shy;ture’s divine Author, both auth&shy;ent&shy;i&shy;cates it to us by His in&shy;ward 
                            wit&shy;ness and opens our minds to under&shy;stand its mean&shy;ing.</li>
                        <li>Being whol&shy;ly and verb&shy;al&shy;ly God-given, Scrip&shy;ture is without error or 
                            fault in all its teach&shy;ing, no less in what it states about God’s acts in 
                            cre&shy;a&shy;tion, about the events of world history, and about its own lit&shy;er&shy;ary 
                            or&shy;i&shy;gins under God, than in its wit&shy;ness to God’s saving grace in 
                            in&shy;div&shy;id&shy;ual lives.</li>
                        <li>The auth&shy;or&shy;ity of Scrip&shy;ture is in&shy;esc&shy;ap&shy;a&shy;bly im&shy;pair&shy;ed 
                            if this tot&shy;al div&shy;ine in&shy;err&shy;an&shy;cy is in any way lim&shy;i&shy;ted or 
                            dis&shy;re&shy;gard&shy;ed, or made rel&shy;avtive to a view of truth con&shy;tra&shy;ry to 
                            the Bible’s own; and such laps&shy;es bring ser&shy;i&shy;ous loss to both the in&shy;div&shy;id&shy;ual 
                            and the Church.</li>
                    </ol> 
                    <p>
                        
                    </p>
                    <p>
                        <strong>Of course, this just scratch&shy;es</strong> the sur&shy;face of the mean&shy;ing 
                        of the Bible. There is so much more to know about this life-giving book. If you want to 
                        ex&shy;per&shy;i&shy;ence a life bey&shy;ond any&shy;thing you could have ever im&shy;ag&shy;ined, 
                        please contact us. We can show you who God real&shy;ly is, and help you be&shy;gin a new 
                        life&mdash;a beau&shy;ti&shy;ful, mean&shy;ing&shy;ful life&mdash;one that will last for&shy;ev&shy;er. 
                    </p>
                </div>
            </div>
        </div>
    </div> 
</div>
<footer class="pad-content footer-color-dark-blue footer-width"></footer>
