<menu-bar class="link-box" style="color:darkblue;font-kerning:auto;letter-spacing:0.01em;" [menuItems]="menuItems"></menu-bar>
<div id="what-we-believe" class="full-page pad-content" 
                          style="background-color: transparent;color:black;height:fit-content;">
    <div class="background-image-bible page-layout">
        <div id="top"></div>
        <div class="title-grid-layout">
            <div class="bubble-light">
                <div class="blue-title" style="display:flex;flex-direction: column;grid-column:5/span 15;grid-row:1/span 10;">
                    <div class="believe-title" >What We Believe</div>
                </div>
                <p class="believe-subheader">Why do we need God?</p>
                <p class="believe-text text-size">
                    <strong>Humanity</strong> has a problem.  It's a a problem that we caused for 
                    our&shy;selves, but we can't fix it our&shy;selves&mdash;it is be&shy;yond 
                    our power to do so.<br><br>
                    <strong>You see,</strong> our first ancest&shy;ors were created to be perfect 
                    beings, but they still had a choice&mdash;they could obey God the Creator, 
                    or they could reject Him. To obey Him would have meant having a perfect 
                    life, and a life that would last forever. But they made the terr&shy;ible 
                    choice to reject God, and when they did that, they brought the sent&shy;ence 
                    of death upon them&shy;selves.  They would be mortal, and they would be unable 
                    to take back the choice they made. What's worse, they passed that sent&shy;ence 
                    of death on to all of us who have come after them.<br><br>                    
                    <strong>So why can't</strong> we fix the prob&shy;lem?  Can't we just 
                    de&shy;cide to obey God?  Can't we set things right on our own?  Sadly, no.  
                    Part of the mort&shy;al&shy;ity we inherit makes it so that we will 
                    always&mdash;<i>always</i>&mdash;sin against God.  It's in&shy;ev&shy;it&shy;able 
                    that we will turn away from God.  It is in&shy;ev&shy;it&shy;able that we 
                    will sin.  And when we sin, we re&shy;in&shy;force the death sent&shy;ence 
                    we in&shy;her&shy;ited. We prove to all of time and e&shy;ter&shy;ni&shy;ty 
                    that we are what God says we are&mdash;sin&shy;ners de&shy;serv&shy;ing 
                    of e&shy;ter&shy;nal pun&shy;ish&shy;ment.<br><br>                    
                    <strong>Will it help</strong> if we do more good deeds than evil ones? 
                    So many people seem to think so.  But God's answer is a resounding No. 
                    The Lord Jesus Christ said, "<span class="biblequote">Therefore you 
                    shall be perfect, just as your Father in heaven is perfect</span> 
                    (<a target="_blank" rel="noopener" href="https://www.biblegateway.com/passage/?search=Matthew+5%3A48&version=NKJV">Matthew 5:48</a>)." 
                    It's not about get&shy;ting to be good e&shy;nough for God.  You 
                    can never, ever be&shy;come good e&shy;nough for God. It is too late.  
                    You are al&shy;rea&shy;dy lost.  That's ter&shy;ri&shy;ble news! 
                    <strong>But God has an ans&shy;wer.</strong><br><br>                    
                    <strong>God says</strong> that sin must be paid for. You can pay 
                    for your own sin by ex&shy;ist&shy;ing in e&shy;ter&shy;nal 
                    pun&shy;ish&shy;ment, or you can let God pay for them.  Is that 
                    for real?  Can God real&shy;ly pay for our sin and take away the 
                    pun&shy;ish&shy;ment we de&shy;serve?  Yes! You see, Jesus Christ, 
                    the Son of God, is no mere mort&shy;al who is sad&shy;dled with the 
                    same pun&shy;ish&shy;ment that we are.  He is, rather, God Him&shy;self, 
                    fused into hu&shy;man&shy;ity, born into un&shy;stained, sin&shy;less 
                    human flesh.  And when He died upon the cross there in Jeru&shy;salem, 
                    he took upon Him&shy;self the ter&shy;ri&shy;ble pun&shy;ish&shy;ment 
                    we de&shy;serve.  And He proved it to be so by rais&shy;ing Him&shy;self 
                    up from death!<br><br>                    
                    <strong>So your choice</strong> now is this: will you con&shy;tin&shy;ue 
                    on your current course toward e&shy;ter&shy;nal de&shy;struc&shy;tion, 
                    or will you turn to God and call on Him to save you from  your sin? 
                    You can today, right where you are, ask Jesus to rescue you from the 
                    pun&shy;ish&shy;ment you de&shy;serve for your re&shy;jec&shy;tion of 
                    God.  You can be born into God's fam&shy;ily.  You can ex&shy;per&shy;i&shy;ence 
                    the am&shy;a&shy;zing free&shy;dom from being trap&shy;ped by your past. 
                    You can ex&shy;per&shy;i&shy;ence a life that is so won&shy;der&shy;ful, 
                    so full of joy, that no&shy;thing you ever ex&shy;per&shy;i&shy;enced 
                    be&shy;fore can match it.<br><br>                    
                    <strong>If you take this step today, please con&shy;tact us and let us 
                    know.  We can help you learn how to con&shy;tin&shy;ue in free&shy;dom, 
                    joy, and peace with God. </strong><br>
                <hr style="width:80%;">
                <br>
                <p class="believe-subheader">Let's talk about what we believe!</p>
                <p class="believe-text text-size">
                    The things we be&shy;lieve go all the way back to the be&shy;gin&shy;nings 
                    of his&shy;tory. Our be&shy;liefs are based on the time&shy;less truth of 
                    the Bible, the re&shy;vealed writ&shy;ten word of God Him&shy;self. The 
                    Bible tells us that this Word of God can&shy;not be broken (<a class="believe-link" target="_blank" rel="noopener" 
                    href="https://www.biblegateway.com/passage/?search=John+10%3A35&version=NKJV">John 10:35</a>). 
                    Be&shy;cause of this, the prom&shy;is&shy;es of God were ful&shy;fil&shy;led 
                    not just through&shy;out hi&shy;story (<a class="believe-link" target="_blank" rel="noopener" 
                    href="https://www.biblegateway.com/passage/?search=Joshua+23%3A14&version=NKJV">Joshua 23:14</a>), but also in 
                    Jesus Christ (<a class="believe-link" target="_blank" rel="noopener" 
                    href="https://www.biblegateway.com/passage/?search=2+corinthians+1%3A20&version=NKJV">2&nbsp;Corinthians 1:20</a>). 
                    And there's so much more about the Bible&mdash;like how God has im&shy;bued 
                    it with a spir&shy;it&shy;ual power (<a class="believe-link" target="_blank" rel="noopener" 
                    href="https://www.biblegateway.com/passage/?search=Hebrews%204%3A12&version=NKJV">Hebrews 4:12</a>) 
                    that can open your eyes to the re&shy;al&shy;ity of some&shy;thing&mdash;Some&shy;one&mdash;great&shy;er 
                    than your&shy;self. Some&shy;one who can turn you into a new per&shy;son. 
                    Some&shy;one who can make the life you have now seem like no&shy;thing com&shy;pared 
                    to what He of&shy;fers you.
                </p>
                <p class="believe-text text-size">
                    Keep reading! 
                </p>
            </div><!--bubble-->
            <br>
</div>
        <div>        
        </div>
        <br><br>
        <div id="the-bible"></div>
        <div class="title-grid-layout">
            <div class="bubble-light">
                <div class="believe-subtitle blue-title" >What We Believe About</div>
                <div class="believe-title blue-title">The Bible</div>
                <p class="believe-subheader">Is this book really from God?</p>
                <div class="believe-text text-size">
                    <p>
                        <strong>Throughout the centuries</strong>, Christians have held that the Bible is God's 
                        revealed Word, given through proph&shy;ets and apostles so that human&shy;ity can come to know 
                        God person&shy;ally and re&shy;ceive eter&shy;nal life. We at St. Paul's Church em&shy;brace this truth 
                        whole&shy;heart&shy;edly, and we teach it as the one and only source of truth for life and hope 
                        for all people every&shy;where. We encour&shy;age our members to read it every day, and we get 
                        together often to study the Bible to deepen our faith and our under&shy;stand&shy;ing of who God is. 
                        One of the great creeds of the Church, the Belgic Con&shy;fes&shy;sion, says,
                    </p>
                    <div class="blockquote" style="margin-bottom:20px;">
                        We con&shy;fess that this Word of God was not sent nor de&shy;liv&shy;ered by the will of man, but 
                        that men spake from God, being moved by the Holy Spir&shy;it, as the apo&shy;stle Peter says; 
                        and that af&shy;ter&shy;wards God, from a spe&shy;cial care which He has for us and our sal&shy;va&shy;tion, 
                        com&shy;mand&shy;ed His ser&shy;vants, the proph&shy;ets and a&shy;post&shy;les, to com&shy;mit His re&shy;vealed word to 
                        writing; and He Him&shy;self wrote with His own fing&shy;er the two tab&shy;les of the law. 
                        There&shy;fore we call such writ&shy;ings holy and div&shy;ine Scrip&shy;tures.
                    </div>
                    <p>
                        <strong>This is the core of what we believe about the Bible:</strong><a target="_blank" rel="noopener" href="https://rcus.org/doctrine/">*</a>
                    </p>
                    <ol>
                        <li>God, who is Him&shy;self Truth and speaks truth only, has in&shy;spired Holy Scrip&shy;ture in 
                            order thereby to reveal Him&shy;self to lost man&shy;kind through Jesus Christ as Cre&shy;at&shy;or 
                            and Lord, Re&shy;deem&shy;er and Judge. Holy Scrip&shy;ture is God’s wit&shy;ness to Him&shy;self.</li>
                        <li>Holy Scrip&shy;ture, being God’s own Word, written by men pre&shy;pared and super&shy;intend&shy;ed 
                            by His Spirit, is of in&shy;fal&shy;lible divine auth&shy;ority in all mat&shy;ters upon 
                            which it touch&shy;es: It is to be be&shy;lieved, as God’s in&shy;struc&shy;tion, in all 
                            that it af&shy;firms; obey&shy;ed, as God’s command, in all that it re&shy;quires; em&shy;braced, 
                            as God’s pledge, in all that it pro&shy;mis&shy;es.</li>
                        <li>The Holy Spirit, Scrip&shy;ture’s divine Author, both auth&shy;ent&shy;i&shy;cates it to us by His in&shy;ward 
                            wit&shy;ness and opens our minds to under&shy;stand its mean&shy;ing.</li>
                        <li>Being whol&shy;ly and verb&shy;al&shy;ly God-given, Scrip&shy;ture is without error or 
                            fault in all its teach&shy;ing, no less in what it states about God’s acts in 
                            cre&shy;a&shy;tion, about the events of world history, and about its own lit&shy;er&shy;ary 
                            or&shy;i&shy;gins under God, than in its wit&shy;ness to God’s saving grace in 
                            in&shy;div&shy;id&shy;ual lives.</li>
                        <li>The auth&shy;or&shy;ity of Scrip&shy;ture is in&shy;esc&shy;ap&shy;a&shy;bly im&shy;pair&shy;ed 
                            if this tot&shy;al div&shy;ine in&shy;err&shy;an&shy;cy is in any way lim&shy;i&shy;ted or 
                            dis&shy;re&shy;gard&shy;ed, or made rel&shy;avtive to a view of truth con&shy;tra&shy;ry to 
                            the Bible’s own; and such laps&shy;es bring ser&shy;i&shy;ous loss to both the in&shy;div&shy;id&shy;ual 
                            and the Church.</li>
                    </ol> 
                    <p>
                    </p>
                    <p>
                        <strong>Of course, this just scratch&shy;es</strong> the sur&shy;face of the mean&shy;ing 
                        of the Bible. There is so much more to know about this life-giving book. If you want to 
                        ex&shy;per&shy;i&shy;ence a life bey&shy;ond any&shy;thing you could have ever im&shy;ag&shy;ined, 
                        please contact us. We can show you who God real&shy;ly is, and help you be&shy;gin a new 
                        life&mdash;a beau&shy;ti&shy;ful, mean&shy;ing&shy;ful life&mdash;one that will last for&shy;ev&shy;er. 
                    </p>
                </div>
            </div>
        </div>
        <div id="faith"></div>
        <div class="title-grid-layout">
            <div class="bubble-light">
                <div class="believe-subtitle blue-title">What We Believe About</div>
                <div class="believe-title blue-title">Faith</div>
                <div class="believe-text text-size">
                    <p class="believe-subheader">What is faith?</p>
                    <p>
                        <strong>Let's start</strong> by describing what it <i>isn't</i>. People talk about 
                        faith being a set of teach&shy;ings, or a mere&shy;ly hu&shy;man be&shy;lief in 
                        some&shy;thing or some&shy;one. But that's not what we're talk&shy;ing about here. 
                        Auth&shy;entic faith in God is not get&shy;ting your&shy;self psyched up over 
                        some&shy;thing. It isn't mere&shy;ly a set of abs&shy;tract re&shy;lig&shy;ious 
                        be&shy;liefs to which you give some int&shy;el&shy;lect&shy;ual a&shy;gree&shy;ment, 
                        eith&shy;er. 
                    </p>
                    <p class="believe-subheader">You can't do it your&shy;self</p>
                    <p>
                        <strong>Just ac&shy;cept&shy;ing</strong> a doc&shy;trine or dog&shy;ma, how&shy;ever valid, 
                        isn't auth&shy;entic faith. Many of us who have grown up in church&shy;es oft&shy;en 
                        take some teach&shy;ing to be true. And e&shy;ven those who nev&shy;er went to church 
                        may have some opin&shy;ion that is con&shy;sist&shy;ent with the teach&shy;ing of Scrip&shy;ture. 
                        Now right op&shy;in&shy;ions and ac&shy;cept&shy;ance of sound doc&shy;trine may point us in 
                        the right di&shy;rec&shy;tion, but that simply does not go far e&shy;nough.
                    </p>
                    <p>
                        And hav&shy;ing emo&shy;tion&shy;al ex&shy;per&shy;i&shy;en&shy;ces 
                        isn't what real faith is about. A per&shy;son to 
                        whom God has given real faith may have mo&shy;ments of high 
                        emo&shy;tion&mdash;what we some&shy;times call "mount&shy;ain&shy;top" 
                        ex&shy;per&shy;i&shy;en&shy;ces&mdash;but e&shy;mo&shy;tions 
                        can fool you.You may be ut&shy;ter&shy;ly lost and with&shy;out 
                        God in the world, while enjoy&shy;ing hap&shy;pi&shy;ness 
                        and think&shy;ing that ev&shy;er&shy;y&shy;thing's 
                        just fine, right up un&shy;til the day you die.
                    </p>
                    <p> 
                        <i>This is where God has to come in.</i>            
                    </p>
        
                    <p class="believe-subheader">The real thing</p>
                    <p>
                        <strong>Real faith</strong>&mdash;the faith that saves you from e&shy;ter&shy;nal 
                        ruin&mdash;is the pro&shy;found su&shy;per&shy;nat&shy;ur&shy;al con&shy;fid&shy;ence 
                        that God is real. 
                        <a class="believe-link" target="_blank" rel="noopener" 
                            href="https://www.biblegateway.com/passage/?search=hebrews+11%3A1&version=NKJV">Hebrews 11:1</a> says, 
                        "<span class="biblequote">Now faith is the sub&shy;stance of things 
                        hoped for, the evi&shy;dence of things not seen.</span>" In other  
                        words, real faith&mdash;the faith that God offers you as a gift&mdash;is 
                        the con&shy;firm&shy;ation of the things that God pro&shy;mises to those 
                        who will open them&shy;selves up to Him. 
                    </p> 
                    <p>    
                        Faith is the proof of the things we can't yet see. Faith 
                        per&shy;ceives the real&shy;ity of the things that aren't 
                        re&shy;vealed to the physical senses. And be&shy;cause 
                        this is a su&shy;per&shy;nat&shy;ural con&shy;fid&shy;ence, 
                        this faith is some&shy;thing that God Him&shy;self must 
                        give you. You can't just decide to agree with it and make 
                        it so. As the great Bible scholar John Cal&shy;vin wrote, 
                        "[T]he know&shy;ledge of faith con&shy;sists more of 
                        cert&shy;ain&shy;ty than dis&shy;cern&shy;ment." It 
                        isn't some&shy;thing you can fig&shy;ure out. It is too big 
                        for your int&shy;el&shy;lect, your soul, your heart.
                    </p>
                    <p>    
                        You see, it is God that has to do the in&shy;it&shy;ia&shy;ting of your 
                        faith, be&shy;cause you are ir&shy;rep&shy;ar&shy;a&shy;bly bro&shy;ken. 
                        Your ab&shy;i&shy;li&shy;ty to per&shy;ceive God was de&shy;stroy&shy;ed 
                        from the mo&shy;ment you came to be. And God knows&mdash;has known from 
                        e&shy;ter&shy;nity past&mdash;who will be&shy;lieve and who won't. If 
                        you are read&shy;ing this now, God may be call&shy;ing to you to come to Him. 
                    </p>
                    <p>
                        Do you want to seek God? If you do, 
                        don't stop here. Get a Bible, or go online to find it. Start reading it&mdash;the 
                        <a class="believe-link" target="_blank" rel="noopener" 
                            href="https://www.biblegateway.com/passage/?search=John%201&version=NKJV">Gos&shy;pel 
                        of John</a> is a good place to start. And then get a hold of us. We'd love to en&shy;cour&shy;age 
                        you in your seek&shy;ing after God.
                    </p>
                </div>
            </div>
        </div>
        <br><br>
        <div id="grace"></div>
        <div class="title-grid-layout">
            <div class="bubble-light">
                <div class="believe-subtitle blue-title" >What We Believe About</div>
                <div class="believe-title blue-title" >God's Grace</div>
                <div class="believe-text text-size">
                    <p class="believe-subheader">What is grace?</p>
                    <p>
                        If you've nev&shy;er been a&shy;round church&shy;es in your life, you may won&shy;der, 
                        "What is this 'grace' I hear a&shy;bout?" Per&shy;haps you have been ex&shy;posed 
                        to Christian churches, but you aren't quite sure what this is sup&shy;posed 
                        to mean. So let's be&shy;gin at the be&shy;gin&shy;ning.
                    </p>
                    <p>
                        The dic&shy;tion&shy;ary has a lot of dif&shy;fer&shy;ent mean&shy;ings for the word "grace"&mdash;
                        ev&shy;er&shy;y&shy;thing from "sim&shy;ple el&shy;e&shy;gance or re&shy;fine&shy;ment of 
                        move&shy;ment" to "a short prayer of thanks said be&shy;fore or aft&shy;er a meal." But 
                        the mean&shy;ing we're talk&shy;ing ab&shy;out is this: <strong>the grace of God is His free, 
                        un&shy;de&shy;serv&shy;ed fa&shy;vor, which He man&shy;i&shy;fests in sav&shy;ing lost, con&shy;demn&shy;ed 
                        hu&shy;man be&shy;ings from their re&shy;bel&shy;lion a&shy;gainst Him.</strong> 
                    </p>
                    <p>
                        Let's break it down: The first asp&shy;ect of God's grace is that it is <strong>free.</strong>
                        If you fill a gro&shy;cery cart full of goods and take it to the check&shy;out aisle, what will 
                        you ex&shy;pect when the check&shy;er has scanned all your food? You're go&shy;ing 
                        to pay for those it&shy;ems, right? But what if the check&shy;er hand&shy;ed you 
                        the re&shy;ceipt, and said, "No charge!" Would&shy;n't you be am&shy;azed? 
                        Would&shy;n't you be grate&shy;ful? <i>Esp&shy;e&shy;cial&shy;ly if you 
                        did&shy;n't have the mon&shy;ey to pay for it?</i> I would. 
                    </p>
                    <p>
                        Now think ab&shy;out this: some peo&shy;ple think that to get to hea&shy;ven, 
                        you have to work hard, do more good deeds than bad, ob&shy;ey the rules, 
                        and other&shy;wise be a "good" per&shy;son. In o&shy;ther words, you have to pay 
                        for hea&shy;ven with how you live your life. But what if you don't have 
                        en&shy;ough? What if you can't af&shy;ford it? <i>What if the price is too high?</i>
                    </p>
                    <p>
                        The sec&shy;ond asp&shy;ect of God's grace is that it is <strong>un&shy;de&shy;serv&shy;ed</strong>. 
                        Not only do you not have e&shy;nough good&shy;ness to get into hea&shy;ven, <i>you don't 
                        even de&shy;serve it</i>. "But why not?" I can hear some&shy;one ask. You see, all 
                        those good deeds you've been stock&shy;pil&shy;ing to pay for your tick&shy;et into 
                        God's pre&shy;sence&mdash;they are all count&shy;er&shy;feit. Pho&shy;ny. Use&shy;less. But how 
                        can that be? God warns us in the Bible, 
                    </p>

                    <div class="biblequote poem">
                        <span>But we are all like an unclean thing,</span><br>
                        <span>And all our righteousnesses are like <strong>filthy rags</strong>;</span><br> 
                        <span>We all fade as a leaf,</span><br> 
                        <span>And our iniquities, like the wind,</span><br> 
                        <span>Have taken us away.</span><br>
                    </div>
                    <p style="margin-left:60px;">&mdash;<a rel="noopener" target="_blank" 
                        href="https://www.biblegateway.com/passage/?search=Isaiah%2064%3A6&version=NKJV">Isaiah 64:6 (NKJV)</a>
                    </p>
                    <p>
                        All those good deeds we think we're do&shy;ing? All that right liv&shy;ing? In 
                        God's eyes, it's <i>filth.</i> 
                    </p> 
                    <p>
                        That's right. God thinks no more of your "good" be&shy;hav&shy;ior than 
                        he does of your sin&shy;ful be&shy;hav&shy;ior. Why? Be&shy;cause all 
                        of hu&shy;man&shy;ity&mdash;each and ev&shy;ery one of us who has 
                        ev&shy;er been born or ev&shy;er will be born&mdash;is cor&shy;rupt&shy;ed 
                        by sin. And cor&shy;rup&shy;tion isn't some&shy;thing you can patch, 
                        or smooth over, or cov&shy;er with a lick of paint. There's only one 
                        thing to do with it&mdash;<strong>de&shy;stroy it</strong>.
                    </p>
                    <p>
                        <i>But God has a plan.</i>
                    </p>
                    <p>
                        And that plan is some&shy;thing we sim&shy;ply don't de&shy;serve. We 
                        de&shy;serve to be de&shy;stroyed. But God made a way to de&shy;stroy 
                        our <i>sin</i>, but save <i>us</i>. And if you will beg God to put 
                        you into His plan...you can be saved. Res&shy;cued. Freed from your 
                        sin. Keep read&shy;ing&mdash;you'll learn what the Lord Jesus Christ 
                        did to put God's plan into mo&shy;tion.
                    </p>
                </div>
            </div>
        </div>
        <br><br>
        <div id="jesus"></div>
        <div class="title-grid-layout">
            <div class="bubble-light">
                <div class="believe-subtitle blue-title" >What We Believe About</div>
                <div class="believe-title blue-title" >Jesus Christ</div>
                <p class="believe-subheader">Who is Jesus?</p>
                <p class="believe-text text-size">
                    <strong>Jesus Christ is God Him&shy;self.</strong> There's a lot more about this that 
                    should be said, but that is the start&shy;ing point of it all. If you un&shy;der&shy;stand 
                    that, great! God stands ready to give you <i>real faith</i> (and maybe you have al&shy;ready 
                    re&shy;ceived this am&shy;az&shy;ing gift!) If you don't have it, you need to read on.
                </p>
                <p class="believe-text text-size">
                    C.S. Lewis, the auth&shy;or of <i>The Chron&shy;icles of Nar&shy;nia</i>, once wrote, 
                </p>
                <div class="blockquote text-size" style="color:black;">
                    I am try&shy;ing here to pre&shy;vent any&shy;one say&shy;ing the real&shy;ly fool&shy;ish thing that peo&shy;ple often say 
                    about Him: I'm ready to ac&shy;cept Jesus as a great mor&shy;al teach&shy;er, but I don't ac&shy;cept his claim 
                    to be God.  That is the one thing we must not say.  A man who was mere&shy;ly a man and said the 
                    sort of things Jesus said would not be a great mor&shy;al teach&shy;er.  He would eith&shy;er be a 
                    lun&shy;a&shy;tic&mdash;on the lev&shy;el with the man who says he is a poached egg&mdash;or else he 
                    would be the Dev&shy;il of Hell.  You must make your choice.  Eith&shy;er this man was, and is, 
                    the Son of God, or else a mad&shy;man or some&shy;thing worse.  You can shut him up for a fool, 
                    you can spit at him and kill him as a de&shy;mon or you can fall at his feet and call him 
                    Lord and God, but let us not come with any pa&shy;tron&shy;iz&shy;ing non&shy;sense about his being a great 
                    human teach&shy;er.  He has not left that open to us.  He did not in&shy;tend to.
                </div>
                <p class="believe-text text-size">
                    <strong>Jesus, the Man born in Beth&shy;le&shy;hem</strong> two thou&shy;sand years ago, who lived a sin&shy;less life 
                    and taught in anc&shy;ient Is&shy;rael for three years, is the Son of God.  That means He is the God the Son, 
                    the sec&shy;ond Per&shy;son of the Tri&shy;une God, fused in&shy;to hu&shy;man&shy;i&shy;ty and born of the vir&shy;gin Mary.  He came to make 
                    it pos&shy;si&shy;ble for us sin&shy;ful, God-re&shy;ject&shy;ing peo&shy;ple to be changed&mdash;born spir&shy;it&shy;ual&shy;ly into a new, clean, 
                    e&shy;ter&shy;nal life. When He died on the cross at Jeru&shy;salem, He took upon Him&shy;self the aw&shy;ful, ter&shy;ri&shy;fy&shy;ing 
                    pun&shy;ish&shy;ment that we de&shy;serve, so that when any&shy;one comes to Him and asks Him to res&shy;cue us from hell, 
                    he sub&shy;sti&shy;tutes his own death for ours. He pays the pen&shy;al&shy;ty for us. He makes us fit to spend a 
                    beaut&shy;i&shy;ful, glor&shy;i&shy;ous e&shy;ter&shy;ni&shy;ty with Him.
                </p>
                <p class="believe-text text-size">
                    <strong>How do we know that Jesus really is the Son of God?</strong> <br>
                    <strong>First, He act&shy;ual&shy;ly claimed to be the Son of God. </strong> 
                    <a class="believe-link" target="_blank"  rel="noopener" href="https://www.biblegateway.com/passage/?search=John%2010%3A22-42&version=NKJV">John 10:24</a> begins,</p>
                <div class="blockquote text-size biblequote">
                    Then the Jews sur&shy;round&shy;ed Him and said to Him, “How long do You keep us in doubt? 
                    If You are the Christ, tell us plain&shy;ly.” Jesus ans&shy;wer&shy;ed them, “I told you, and 
                    you do not be&shy;lieve. The works that I do in My Fa&shy;ther’s name, they bear wit&shy;ness 
                    of Me. But you do not be&shy;lieve, be&shy;cause you are not of My sheep, as I said to you. 
                    My sheep hear My voice, and I know them, and they fol&shy;low Me. And I give them 
                    e&shy;ter&shy;nal life, and they shall never per&shy;ish; neith&shy;er shall any&shy;one snatch them out 
                    of My hand. My Fa&shy;ther, who has given them to Me, is great&shy;er than all; and no one is 
                    able to snatch them out of My Fa&shy;ther’s hand. <span class="highlight"><i>I and My Father are one.</i></span>”</div>
                <p class="believe-text text-size">A little farther down, the Scripture reads,</p>
                <div class="blockquote text-size biblequote">
                    Then the Jews took up stones again to stone Him. Jesus ans&shy;wer&shy;ed them, “Many good 
                    works I have shown you from My Fa&shy;ther. For which of those works do you stone Me?” 
                    The Jews ans&shy;wer&shy;ed Him, saying, “For a good work we do not stone You, but for blas&shy;phem&shy;y, 
                    and be&shy;cause You, being a Man, make Your&shy;self God.” Jesus ans&shy;wer&shy;ed them, “Is it not 
                    writ&shy;ten in your law, ‘I said, “You are gods” ’? If He called them gods, to whom 
                    the word of God came (and the Scrip&shy;ture can&shy;not be bro&shy;ken), do you say of Him whom 
                    the Father sanct&shy;i&shy;fied and sent into the world, ‘You are blas&shy;pheming,’ <span class="highlight"><i>because I 
                    said, ‘I am the Son of God’</i></span>? If I do not do the works of My Fa&shy;ther, do not be&shy;lieve 
                    Me; but if I do, though you do not be&shy;lieve Me, be&shy;lieve the works, that you may know 
                    and be&shy;lieve that the Fa&shy;ther is in Me, and I in Him.”</div>
                <p class="believe-text text-size">
                    Those around Him rec&shy;og&shy;nized that He was mak&shy;ing that bold claim, and they hat&shy;ed Him for it. And He did&shy;n't deny it&mdash;He 
                    stood firm in mak&shy;ing that claim. And there are many other pla&shy;ces in the Gos&shy;pels where He as&shy;sert&shy;ed his auth&shy;ent&shy;ic claim to 
                    be the Son of God.
                </p>
                <p class="believe-text text-size">
                    <strong>Sec&shy;ond, He proved it again and again by migh&shy;ty mir&shy;a&shy;cles</strong> like heal&shy;ing the sick, re&shy;stor&shy;ing sight to 
                    the blind, giv&shy;ing hear&shy;ing to the deaf, mak&shy;ing the lame walk, cast&shy;ing out de&shy;mons, raising the dead. Over and over 
                    again Jesus demonstrated his awesome power, leaving those who followed Him with no doubt&mdash;this Man Jesus 
                    was truly God in the flesh. There were so many mir&shy;a&shy;cles that 
                    <a class="believe-link" target="_blank"  rel="noopener" href="https://www.biblegateway.com/passage/?search=John%2020%3A24-31&version=NKJV">the Gospel writer declares</a>, 
                    <span style="color:darkblue;">
                        "And tru&shy;ly Jesus did many oth&shy;er signs in the pre&shy;sence of His dis&shy;ci&shy;ples, 
                        which are not writ&shy;ten in this book; but these are writ&shy;ten that you may 
                        be&shy;lieve that Jesus is the Christ, the Son of God, and that be&shy;liev&shy;ing 
                        you may have life in His name."
                    </span>
                </p>
                <p class="believe-text text-size">
                    <strong>Third, He raised Him&shy;self from death</strong> after his cru&shy;ci&shy;fix&shy;ion to prove His div&shy;ine pow&shy;er and His 
                    right to save us from hell. The first clue is that Jesus claim&shy;ed the pow&shy;er to raise Him&shy;self from the dead. 
                    In John 10:17-18, Jesus says, "<span class="biblequote">There&shy;fore My Fa&shy;ther loves Me, be&shy;cause I lay down 
                    My life that I may take it again. No one takes it from Me, but I lay it down of My&shy;self. I have pow&shy;er to 
                    lay it down, </span><span style="color:red"><i>and I have pow&shy;er to take it again</i>.</span>" Jesus, 
                    being God in the flesh, has all pow&shy;er of life and death at his com&shy;mand. That's why Jesus was able to rise 
                    from the dead:
                </p>
                <div class="blockquote text-size biblequote">
                    Now the first day of the week Mary Mag&shy;dal&shy;ene went to the tomb ear&shy;ly, while it 
                    was still dark, and saw that the stone had been tak&shy;en away from the tomb. Then 
                    she ran and came to Simon Peter, and to the other dis&shy;ci&shy;ple, whom Jesus loved, 
                    and said to them, “They have tak&shy;en away the Lord out of the tomb, and we do not 
                    know where they have laid Him.” Peter there&shy;fore went out, and the other dis&shy;ci&shy;ple, 
                    and were go&shy;ing to the tomb. So they both ran to&shy;ge&shy;ther, and the oth&shy;er dis&shy;ci&shy;ple 
                    out&shy;ran Peter and came to the tomb first. And he, stoop&shy;ing down and look&shy;ing in, 
                    saw the lin&shy;en cloths ly&shy;ing there; yet he did not go in. Then Simon Peter came, 
                    fol&shy;low&shy;ing him, and went into the tomb; and he saw the lin&shy;en cloths ly&shy;ing there, 
                    and the hand&shy;ker&shy;chief that had been a&shy;round His head, not ly&shy;ing with the lin&shy;en 
                    cloths, but fold&shy;ed to&shy;ge&shy;ther in a place by it&shy;self. Then the other dis&shy;ci&shy;ple, who 
                    came to the tomb first, went in also; and he saw and be&shy;lieved. For as yet they 
                    did not know the Scrip&shy;ture, that He must rise again from the dead. Then the 
                    dis&shy;ci&shy;ples went away a&shy;gain to their own homes. 
                    <br/><br/>
                    But Mary stood out&shy;side by the tomb weep&shy;ing, and as she wept she stooped down 
                    and looked into the tomb. And saw two ang&shy;els in white sit&shy;ting, one at the head 
                    and the oth&shy;er at the feet, where the body of Jesus had lain. Then they said 
                    to her, “Wom&shy;an, why are you weep&shy;ing?” She said to them, “Be&shy;cause they have 
                    tak&shy;en a&shy;way my Lord, and I do not know where they have laid Him.” Now when 
                    she had said this, she turned a&shy;round and saw Jesus stand&shy;ing there, and did 
                    not know that it was Jesus. Jesus said to her, “Wom&shy;an, why are you weep&shy;ing? 
                    Whom are you seek&shy;ing?” She, sup&shy;pos&shy;ing Him to be the gar&shy;den&shy;er, said to Him, 
                    “Sir, if You have car&shy;ried Him a&shy;way, tell me where You have laid Him, and I 
                    will take Him away.” Jesus said to her, “Mary!” She turned and said to Him, 
                    “Rab&shy;boni!” (which is to say, Teach&shy;er). Jesus said to her, “Do not cling to 
                    Me, for I have not yet as&shy;cend&shy;ed to My Fa&shy;ther; but go to My breth&shy;ren and say 
                    to them, ‘I am as&shy;cend&shy;ing to My Fa&shy;ther and your Fa&shy;ther, and to My God and your 
                    God.’” Mary Mag&shy;dal&shy;ene came and told the dis&shy;ci&shy;ples that she had seen the Lord, 
                    and that He had spok&shy;en these things to her. (<a class="believe-link" target="_blank"  rel="noopener" 
                    href="https://www.biblegateway.com/passage/?search=John+20%3A+1-18&version=NKJV">John 20:1-18</a>)       
                </div>
                <br>
                <p class="believe-subheader">What does this mean?</p>
                <p class="believe-text text-size">
                    The death and re&shy;sur&shy;rec&shy;tion of the Lord Jesus Christ made it 
                    pos&shy;si&shy;ble for God to <a target="_blank" rel="noopener" 
                    href="https://www.biblegateway.com/passage/?search=Romans+5%3A6-11&version=NKJV">take away all your sin</a> and 
                    <a target="_blank" rel="noopener" href="https://www.biblegateway.com/passage/?search=2+Corinthians+5%3A17&version=NKJV">cre&shy;ate a new, 
                    per&shy;fect crea&shy;ture with&shy;in you</a>. <a target="_blank" rel="noopener" 
                    href="https://www.biblegateway.com/passage/?search=john+8%3A36&version=NKJV">You can be tru&shy;ly free</a> 
                    from your life of self-de&shy;struc&shy;tion, and you can es&shy;cape the <a target="_blank" rel="noopener" 
                    href="https://www.biblegateway.com/passage/?search=Luke+16%3A23-26&version=NKJV">e&shy;ter&shy;nal ag&shy;ony that waits 
                    for you</a>.  
                </p>
                <p class="believe-text text-size">
                    <strong>Jesus is the Christ, the Mes&shy;siah, the one and only Son of God. He wants to 
                    show you how much you need Him, and save you from your&shy;self and from e&shy;ter&shy;nal 
                    pun&shy;ish&shy;ment. Be&shy;lieve Him. And get a hold of us&mdash;we can ex&shy;plain to you 
                    how to find that a&shy;maz&shy;ing free&shy;dom in Christ.</strong>
                </p>
            </div>
        </div>
        <br><br>
        <div id="meaning"></div>
        <div class="title-grid-layout">
            <div class="bubble-light">
                <div class="believe-subtitle blue-title" >What We Believe About</div>
                <div class="believe-title blue-title" style="word-wrap: break-word;">Life's Meaning</div>
                <p class="believe-subheader">Why are we here, anyway?</p>
                <div class="believe-text text-size">
                    <p>
                        The Westminster Shorter Catechism states:
                    </p>
                    <p class="blockquote text-size">
                        <i>What is the chief end of man? </i><br>Man's chief end is to glorify God, and to enjoy Him forever.
                    </p>
                    <p>The Bible says:</p>
                    <div class="biblequote poem">
                        <span>In Your presence is fullness of joy;</span><br>
                        <span>At Your right hand are pleasures forevermore.</span><br>
                        &mdash;<a target="_blank" rel="noopener" href="https://www.biblegateway.com/passage/?search=Psalm+16%3A11&version=NKJV">Psalm 16:11 (NKJV)</a>
                    </div><br>
                    <div class="biblequote poem">
                        <span>And my soul shall be joyful in the </span><span style="font-variant:small-caps;">Lord;</span><br>
                        <span>It shall rejoice in His salvation.</span><br>
                        &mdash;<a target="_blank" rel="noopener" href="https://www.biblegateway.com/passage/?search=Psalm%2035%3A9&version=NKJV">Psalm 35:9</a>
                    </div><br>
                    <div class="biblequote poem">
                        <span>My soul shall be satisfied as with marrow and fatness,</span><br>
                        <span>And my mouth shall praise You with joyful lips.</span><br>
                        &mdash;<a target="_blank" rel="noopener" href="https://www.biblegateway.com/passage/?search=Psalm%2063%3A5&version=NKJV">Psalm 63:5</a>
                    </div><br>
                    <div class="biblequote poem">
                        <span>These things I have spoken to you, that My joy may remain in you, and that your joy may be full.</span><br>
                        &mdash;<a target="_blank" rel="noopener" href="https://www.biblegateway.com/passage/?search=John%2015%3A11&version=NKJV">John 15:11</a>
                    </div><br>
                    <p>
                        Im&shy;a&shy;gine that! We can spend for&shy;ever and ever in a 
                        glor&shy;ious cy&shy;cle of love in which we make God 
                        a&shy;maz&shy;ing&shy;ly happy, and God makes us 
                        a&shy;maz&shy;ing&shy;ly hap&shy;py in return.
                    </p>
                    <p>And that for&shy;ev&shy;er can be&shy;gin <i>now.</i></p>
                    <p>
                        Your life can have a meaning exalted above the grinding of this world. 
                        Your life can be changed in ways you can't begin to imagine. Your life 
                        can have a purpose with results that will shine for all of eternity.
                    </p>
                    <p>
                        Get in touch with us. Let us show you how you can have love, 
                        joy, and peace in a life that lifts you up to God Himself.
                    </p>
                </div>
            </div>
        </div>
        <br><br>
        <div id="nutshell"></div>
        <div class="title-grid-layout">
            <div class="bubble-light">
                <div class="believe-subtitle blue-title" >This is Our Faith</div>
                <div class="believe-title blue-title" >In a Nutshell</div>
                <div class="believe-text text-size">
                    <p>
                        Five hundred years ago, the pi&shy;on&shy;eers of our 
                        faith laid out five prin&shy;ci&shy;ples. We call them the Five <i>Solas</i>, 
                        because in the or&shy;ig&shy;in&shy;al Latin, the name of each prin&shy;ci&shy;ple 
                        began with the word <i>Sola</i> or a form of it, which means "Alone". Here they are:
                    </p> 
                    <div class="believe-text text-size" style="width:fit-content;height:fit-content;border:2px solid darkblue;margin: 0 10%;background-color:white;font-size:1em;margin-bottom:15px;">
                        <strong><i>Sola Scriptura</i>&mdash;Scripture Alone</strong>
                        <p style="margin-left:15px;"><a href="../#the-bible">The Bible</a>, being the Word of God (<a  class="believe-link" target="_blank" rel="noopener" 
                            href="https://www.biblegateway.com/passage/?search=2+Peter+1%3A19-21&version=NKJV">2&nbsp;Peter 1:19-21</a>), 
                            is the ul&shy;ti&shy;mate au&shy;thor&shy;ity (<a  class="believe-link" target="_blank" rel="noopener" 
                            href="https://www.biblegateway.com/passage/?search=Luke+4%3A4&version=NKJV">Luke 4:4</a>), 
                            not the trad&shy;it&shy;ions of the church or the opin&shy;ions of men (<a class="believe-link" target="_blank" rel="noopener" 
                            href="https://www.biblegateway.com/passage/?search=Matthew+4%3A4&version=NKJV">Matthew 4:4</a>, 
                            <a class="believe-link" target="_blank" rel="noopener" 
                            href="https://www.biblegateway.com/passage/?search=John+17%3A17&version=NKJV">John 17:17</a>).
                        </p>
                        <strong><i>Sola Gratia</i>&mdash;Grace Alone</strong>
                        <p style="margin-left:15px;">Salvation is by <a href="../#grace">God's grace alone</a> (<a class="believe-link" target="_blank" rel="noopener" 
                            href="https://www.biblegateway.com/passage/?search=Ephesians+2%3A4-9&version=NKJV">Ephesians 2:4-9</a>). 
                            Get&shy;ting to hea&shy;ven does not de&shy;pend upon how well we live.  God chose to save Jacob and 
                            re&shy;ject Esau be&shy;fore they were born (<a class="believe-link" target="_blank" rel="noopener" 
                            href="https://www.biblegateway.com/passage/?search=Romans+9%3A11-13&version=NKJV">Romans 9:11-13</a>).
                        </p>
                        <strong><i>Sola Fide</i>&mdash;Faith Alone</strong>
                        <p style="margin-left:15px;">
                            We are just&shy;i&shy;fied by <a href="../#faith">faith 
                            al&shy;one</a>, a&shy;part from our works (<a class="believe-link" target="_blank" rel="noopener" 
                            href="https://www.biblegateway.com/passage/?search=Romans+3%3A28&version=NKJV">Romans 3:28</a>, 
                            <a class="believe-link" target="_blank" rel="noopener" 
                            href="https://www.biblegateway.com/passage/?search=Philippians+1%3A29&version=NKJV">Philippians 1:29</a>). 
                            Through faith a&shy;lone we re&shy;ceive Christ 
                            and His per&shy;fect right&shy;eous&shy;ness, and are there&shy;by 
                            grant&shy;ed e&shy;ter&shy;nal par&shy;don.
                        </p>
                        <strong><i>Solus Christus</i>&mdash;Christ Alone</strong>
                        <p style="margin-left:15px;">God saves us be&shy;cause of <a href="../#jesus">Jesus 
                            Christ a&shy;lone</a>, and not be&shy;cause of any&shy;thing we do 
                            (<a class="believe-link" target="_blank" rel="noopener" 
                            href="">Ephesians 2:8-10</a>). Just as all men are born sin&shy;ners be&shy;cause of Adam's sin, so all be&shy;lie&shy;vers re&shy;ceive e&shy;ter&shy;nal par&shy;don be&shy;cause of Christ's 
                            blood a&shy;tone&shy;ment on the cross. Eve&shy;ry&shy;thing we do is sim&shy;ply a re&shy;sponse of gra&shy;ti&shy;tude to God for sav&shy;ing us through Christ (<a class="believe-link" target="_blank" 
                            rel="noopener" href="https://www.biblegateway.com/passage/?search=Romans+1%3A21&version=NKJV">Romans 1:21</a>, 
                            <a class="believe-link" target="_blank" rel="noopener" href="https://www.biblegateway.com/passage/?search=Joshua+24%3A14&version=NKJV">Joshua 24:14</a>).
                        </p>
                        <strong><i>Soli Deo Gloria</i>&mdash;For the Glory of God Alone</strong>
                        <p style="margin-left:15px;">God a&shy;lone de&shy;serves all the glo&shy;ry and the praise for the sal&shy;va&shy;tion of His peo&shy;ple (<a class="believe-link" target="_blank" 
                            rel="noopener" href="https://www.biblegateway.com/passage/?search=Philippians+2%3A13&version=NKJV">Philippians 2:13</a>). 
                            If man's sal&shy;va&shy;tion de&shy;pends ul&shy;ti&shy;mate&shy;ly upon man's choice, then man de&shy;serves the glory and praise.  We bring praise to God by serv&shy;ing 
                            Him ac&shy;cord&shy;ing to His Word and by the power of His Spi&shy;rit (<a class="believe-link" target="_blank" rel="noopener" 
                            href="https://www.biblegateway.com/passage/?search=Ephesians+5%3A8-9&version=NKJV">Ephesians 5:8-9</a>). This is the <a href="../#meaning">true meaning of life</a>.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <br><br>
   </div> 

</div>
<footer class="pad-content footer-color-dark-blue" style="margin:-20px 20px 0 20px;"></footer>
