import { Component } from '@angular/core';

@Component({
  selector: 'app-jesus',
  templateUrl: './jesus.component.html',
  styleUrls: ['./jesus.component.css']
})
export class JesusComponent {

}
