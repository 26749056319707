<div id="prayer" class="full-page pad-content" 
                       style="background-color: transparent;color:black;">
    <div class="background-image-church-family page-layout">
        <div class="link-box">
            <a href="" class="text-outline-white link-button outlined-text-brown;" style="color:brown">Main Page</a>
            <a href="../#/who-are-we" class="text-outline-white link-button outlined-text-brown;" style="color:brown">Who We Are</a>
            <a href="../#/what-we-believe" class="text-outline-white link-button outlined-text-brown;" style="color:brown">What We Believe</a>
        </div>

        <div class="text-outline-brown intro-title-grid title-grid-layout">
            <div style="display:flex;flex-direction: column;grid-column:5/span 15;grid-row:1/span 10;">
                <div style="border: transparent 2px solid;width:100%;font-size:5.0em;">Prayer</div>
            </div>
        </div>

        <div class="text-outline-brown text-grid-layout">        
        </div>
    </div> 
</div>
<footer class="pad-content" style="width:97vw;"></footer>
