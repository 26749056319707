<menu-bar class="link-box" style="color:darkblue;font-kerning:auto;letter-spacing:0.01em;" [menuItems]="menuItems"></menu-bar>
<div id="the-solas" class="full-page pad-content" 
                    style="background-color: transparent;color:black;height:fit-content;">
        <div class="background-image-bible page-layout">

        <div>        
            <div class="bubble-light">
                <div style="display:flex;flex-direction: column;text-align: center;">
                    <div class="blue-title subtitle">What We Believe About</div>
                    <div class="blue-title title">The Five <i>Solas</i></div>
                </div>
                <p class="dark-text text">
                    Five hundred years ago, the pi&shy;on&shy;eers of our Reformed 
                    faith laid out five prin&shy;ci&shy;ples. We call them the Five <i>Solas</i>, 
                    because in the or&shy;ig&shy;in&shy;al Latin, the name of each prin&shy;ci&shy;ple 
                    began with the word <i>Sola</i> or a form of it, which means "Alone". Here they are:
                </p>
                <div class="dark-text" class="inset-box" >
                    <strong><i>Sola Scriptura</i>&mdash;Scripture Alone</strong>
                    <p style="margin-left:15px;"><a href="../#the-bible">The Bible</a>, being the Word of God (<a  class="believe-link" 
                        target="_blank" rel="noopener" 
                        href="https://www.biblegateway.com/passage/?search=2+Peter+1%3A19-21&version=NKJV">2&nbsp;Peter 1:19-21</a>), 
                        is the ul&shy;ti&shy;mate au&shy;thor&shy;ity (<a  class="believe-link" target="_blank" rel="noopener" 
                        href="https://www.biblegateway.com/passage/?search=Luke+4%3A4&version=NKJV">Luke 4:4</a>), 
                        not the trad&shy;it&shy;ions of the church or the opin&shy;ions of men (<a class="believe-link" target="_blank" rel="noopener" 
                        href="https://www.biblegateway.com/passage/?search=Matthew+4%3A4&version=NKJV">Matthew 4:4</a>, 
                        <a class="believe-link" target="_blank" rel="noopener" 
                        href="https://www.biblegateway.com/passage/?search=John+17%3A17&version=NKJV">John 17:17</a>).
                    </p>
                    <strong><i>Sola Gratia</i>&mdash;Grace Alone</strong>
                    <p style="margin-left:15px;">Salvation is by <a href="../#grace">God's grace alone</a> (<a class="believe-link" 
                        target="_blank" rel="noopener" 
                        href="https://www.biblegateway.com/passage/?search=Ephesians+2%3A4-9&version=NKJV">Ephesians 2:4-9</a>). 
                        Get&shy;ting to hea&shy;ven does not de&shy;pend upon how well we live.  God chose to save Jacob and 
                        re&shy;ject Esau be&shy;fore they were born (<a class="believe-link" target="_blank" rel="noopener" 
                        href="https://www.biblegateway.com/passage/?search=Romans+9%3A11-13&version=NKJV">Romans 9:11-13</a>).
                    </p>
                    <strong><i>Sola Fide</i>&mdash;Faith Alone</strong>
                    <p style="margin-left:15px;">We are just&shy;i&shy;fied by <a href="../#faith">faith al&shy;one</a>, 
                        a&shy;part from our works (<a class="believe-link" target="_blank" rel="noopener" 
                        href="https://www.biblegateway.com/passage/?search=Romans+3%3A28&version=NKJV">Romans 3:28</a>, 
                        <a class="believe-link" target="_blank" rel="noopener" 
                        href="https://www.biblegateway.com/passage/?search=Philippians+1%3A29&version=NKJV">Philippians 1:29</a>). 
                        Through faith a&shy;lone we re&shy;ceive Christ 
                        and His per&shy;fect right&shy;eous&shy;ness, and are there&shy;by grant&shy;ed e&shy;ter&shy;nal par&shy;don.
                    </p>
                    <strong><i>Solus Christus</i>&mdash;Christ Alone</strong>
                    <p style="margin-left:15px;">God saves us be&shy;cause of <a href="../#jesus">Jesus Christ a&shy;lone</a>, 
                        and not be&shy;cause of any&shy;thing we do (<a class="believe-link" target="_blank" rel="noopener" 
                        href="">Ephesians 2:8-10</a>). Just as all men are born sin&shy;ners be&shy;cause of Adam's sin, so 
                        all be&shy;lie&shy;vers re&shy;ceive e&shy;ter&shy;nal par&shy;don be&shy;cause of Christ's 
                        blood a&shy;tone&shy;ment on the cross. Eve&shy;ry&shy;thing we do is sim&shy;ply a re&shy;sponse 
                        of gra&shy;ti&shy;tude to God for sav&shy;ing us through Christ (<a class="believe-link" target="_blank" 
                        rel="noopener" href="https://www.biblegateway.com/passage/?search=Romans+1%3A21&version=NKJV">Romans 1:21</a>, 
                        <a class="believe-link" target="_blank" rel="noopener" 
                        href="https://www.biblegateway.com/passage/?search=Joshua+24%3A14&version=NKJV">Joshua 24:14</a>).
                    </p>
                    <strong><i>Soli Deo Gloria</i>&mdash;For the Glory of God Alone</strong>
                    <p style="margin-left:15px;">God a&shy;lone de&shy;serves all the glo&shy;ry and the praise for the 
                        sal&shy;va&shy;tion of His peo&shy;ple (<a class="believe-link" target="_blank" 
                        rel="noopener" href="https://www.biblegateway.com/passage/?search=Philippians+2%3A13&version=NKJV">Philippians 2:13</a>). 
                        If man's sal&shy;va&shy;tion de&shy;pends ul&shy;ti&shy;mate&shy;ly upon man's choice, then 
                        man de&shy;serves the glory and praise.  We bring praise to God by serv&shy;ing 
                        Him ac&shy;cord&shy;ing to His Word and by the power of His Spi&shy;rit (<a class="believe-link" target="_blank" rel="noopener" 
                        href="https://www.biblegateway.com/passage/?search=Ephesians+5%3A8-9&version=NKJV">Ephesians 5:8-9</a>). 
                        This is the <a href="../#meaning">true meaning of life</a>.
                    </p>
                </div>
            </div><br>
        </div>
    </div> 
</div>
<footer class="pad-content footer-color-dark-blue" style="width:97vw;"></footer>
