<menu-bar class="link-box" style="color:darkblue;width:100%;word-wrap: break-word;" [menuItems]="menuItems"></menu-bar>
<div id="intro" class="full-page pad-content page-dimensions">
    <div class="background-image-intro" style="min-height:100%;background-attachment: fixed;background-position:bottom;background-repeat:no-repeat;background-size:cover;">
            <div class="intro-title-grid intro-title-div">
                <div class="church-logo">
                    <img alt="St. Paul Evangelical Reformed Church, Hamburg, Minnesota" src="../../assets/img/ChurchLogo.InvertedColor.png"/>
                </div>
                <div class="church-name">
                    <div class="page-title">St. Paul's Church</div>
                    <div style="border: transparent 2px solid;width:100%;font-size:2em;">Hamburg, Minnesota</div>
                </div>
            </div>
            <div class="intro-text-div">        
                <p class="welcome-text">Hello! We're glad you stopped by!</p>
                <p class="intro-text">There's so much we want to tell you about ourselves.  Please <span style="font-weight:450;">click 
                    one of the links at the top of the page</span> to find out more about us.  
                </p><br>
            </div>
    </div> 
</div>
<div id="church-family" class="full-page pad-content page-dimensions" style="margin-top:-40px">
    <div class="background-image-church-family family-slides">
        <div class="grid-for-text">
            <div class="title-text narrow-family-text">Our Church Family</div>
            <div class="church-family-links">
                <div><button routerLink="/who-are-we" class="church-family-link-button">Who are we?</button></div>
                <div><button routerLink="/what-to-expect" class="church-family-link-button">What can I expect?</button></div>
                <div><button routerLink="/what-we-believe" class="church-family-link-button">What do we believe?</button></div>
                <hr>
                <div><button routerLink="/bulletin-list" class="church-family-link-button">Church Bulletins</button></div>
                <div><button routerLink="/sermons" class="church-family-link-button">Pastor's Sermons</button></div>
                <div><button routerLink="/tithing" class="church-family-link-button">Member Giving</button></div>
            </div>
        </div>
    </div> 
</div>
<div id="global-family" class="full-page pad-content page-dimensions" style="margin-top:-40px;background-color: transparent;"  >
    <div class="background-image-global-family global-slides">
        <div class="grid-for-text">
            <div class="title-text narrow-family-text">Our Global Family</div>
            <div class="global-family-links">
                <div style="margin-bottom:1vh;">
                    <a href="https://rcus.org" target="_blank" rel="noopener" class="church-family-link-button">Our Denomination</a>
                </div>
                <div style="margin-bottom:1vh;">
                    <button routerLink="/foreign-missions" class="church-family-link-button">Our Overseas<br>Partnerships</button>
                </div>
                <div><button routerLink="/join-with-us" class="church-family-link-button">Our Educational<br>Institutions</button></div>
            </div>
        </div>
    </div>
</div>
<div id="find-us" class="full-page pad-content page-dimensions" 
    style="margin-top:-5px;background-color: transparent;display:grid;grid-template-columns: 33% 33% 33%;grid-template-rows:33% 33% 33%;">
    <div class="find-us-title-text">How to<br>Find Us</div>
    <div class="find-us-text">
        We're out in the country, a beautiful drive a few miles south of Norwood 
        Young America. Use the helpful map to get directions to us from anywhere!
    </div>
    <div class="background-image-find-us"></div>
    <div id="outer-map-container" class="map-container">
        <google-map class="map" [options]="mapOptions" ></google-map>
    </div>
</div>
<footer class="pad-content footer-color-dark-blue footer-width"></footer>
