<div id="footer" class="text-outline footer-grid">
    <p class="footer-title">St. Paul's Evangelical Reformed Church</p>
    <p class="footer-address">15470 County Road 31, Hamburg, Minnesota</p>
    <p class="footer-subtitle">A member of the Reformed Church in the United States</p>
    <div class="more-about-us">More About Us</div>
    <div class="about-us-links">
        <button id="cell-1" routerLink="/who-are-we" class="link-button text-outline">Who are we?</button>
        <button id="cell-2" routerLink="/what-we-believe" class="link-button text-outline">What We Believe</button>
        <button id="cell-3" routerLink="/the-solas" class="link-button text-outline">The Five Solas</button>
        <button id="cell-4" routerLink="/what-we-believe" fragment="jesus" class="link-button text-outline">About Jesus</button>
        <button id="cell-5" routerLink="/what-we-believe" fragment="faith" class="link-button text-outline">About Faith</button>
        <button id="cell-6" routerLink="/what-we-believe" fragment="the-bible" class="link-button text-outline">About the Bible</button>
        <button id="cell-7" routerLink="/what-we-believe" fragment="grace" class="link-button text-outline">About Grace</button>
        <button id="cell-8" routerLink="/prayer" class="link-button text-outline">About Prayer</button>
        <button id="cell-9" routerLink="/giving" class="link-button text-outline">About Giving</button>
        <button id="cell-10" routerLink="/meaning" class="link-button text-outline">About Life's Meaning</button>
        <button id="cell-11" routerLink="/sermons" class="link-button text-outline">Sermons</button>
        <button id="cell-12" routerLink="/local-outreach" class="link-button text-outline">Local Outreach</button>
        <button id="cell-13" routerLink="/foreign-missions" class="link-button text-outline">Foreign Missions</button>
        <button id="cell-14" routerLink="/higher-ed" class="link-button text-outline">Higher Education</button>
        <button id="cell-15" onclick="window.open('https://rcus.org','_blank');" class="link-button text-outline">Our Denomination</button>
        <button id="cell-16" onclick="window.open('https://www.facebook.com/stpaulsrcus/', '_blank');" class="link-button text-outline">Facebook</button>
        <button id="cell-17" onclick="window.open('https://www.youtube.com/channel/UCjlD7UGmvz7fSqIHrklPIXQ', '_blank');" class="link-button text-outline">YouTube</button>
        <button id="cell-18" class="link-button text-outline">Member Giving</button>
    </div>
</div>
