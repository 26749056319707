<div id="who-are-we" class="full-page pad-content" 
                     style="background-color: transparent;color:black;height:150vh;">
    <div class="background-image-church-family page-layout">
        <div class="link-box">
            <a href="" class="text-outline-white link-button outlined-text-brown" style="color:brown">Main Page</a>
            <a href="../#/what-we-believe" class="text-outline-white link-button outlined-text-brown;" style="color:brown">What We Believe</a>
            <a href="../#/join-with-us" class="text-outline-white link-button outlined-text-brown;" style="color:brown">Join With Us</a>
        </div>
        <div class="text-outline-brown title-grid-layout">
            <div style="display:flex;flex-direction: column;grid-column:5/span 15;grid-row:1/span 10;">
                <div style="border: transparent 2px solid;width:100%;font-size:1.6em;">What We Believe About</div>
                <div style="border:transparent 2px solid;width:100%;font-size:5.0em;">Giving</div>
            </div>
        </div>
        <div class="text-outline-brown">
            <div class="bubble-dark"  style="line-height:29px;padding-top:20px;">
                <p class="white-text">We teach that when some&shy;one joins our church fam&shy;ily, they 
                    should pray care&shy;ful&shy;ly about what they should reg&shy;u&shy;lar&shy;ly give 
                    to sup&shy;port the work of God here at St. Paul's. <strong>But we don't ask our vi&shy;si&shy;tors</strong> 
                    to con&shy;trib&shy;ute un&shy;less they want to.  And for those who do be&shy;long 
                    to our fam&shy;ily, we trust them to do what God calls them to do&mdash;we don't check 
                    up on them, and we only track what mem&shy;bers give so they can claim the don&shy;a&shy;tions 
                    for tax pur&shy;pos&shy;es.  But we do seek to share what the Bible teach&shy;es 
                    about giv&shy;ing, be&shy;cause it is an im&shy;port&shy;ant sub&shy;ject that God 
                    talks about a lot in the Scrip&shy;ture.  
                </p>
                <p class="white-text">
                    If you are a member and would like to give to the church online, <a class="white-link" href="../#/tithing">click here.</a>
                </p>
                <p class="white-subheader" style="width:100%;margin-left:0%;"><strong>What the Bible says about giving</strong></p>
                <p class="white-text">In the Old Testament God com&shy;mand&shy;ed that a tithe, ten per&shy;cent, of all that He has 
                    prov&shy;id&shy;ent&shy;ial&shy;ly blessed us with be giv&shy;en back to God. We read in 
                    <a class="white-link" target="_blank"  rel="noopener" href="https://www.biblegateway.com/passage/?search=Leviticus+27%3A32&version=NKJV">Leviticus 27:32</a>, 
                    <strong>"[C]on&shy;cern&shy;ing the tithe of the herd or the flock, of what&shy;ever pas&shy;ses un&shy;der the rod, the tenth one shall be holy to the LORD," </strong> 
                    (see also 
                    <a class="white-link" target="_blank"  rel="noopener" href="https://www.biblegateway.com/passage/?search=Numbers+18%3A26&version=NKJV">Numbers 18:26</a>, 
                    <a class="white-link" target="_blank"  rel="noopener" href="https://www.biblegateway.com/passage/?search=Deuteronomy+14%3A24&version=NKJV">Deuteronomy 14:24</a>, 
                    <a class="white-link" target="_blank"  rel="noopener" href="https://www.biblegateway.com/passage/?search=2+Chronicles+31%3A5&version=NKJV">2&nbsp;Chronicles 31:5</a>). 
                    Even with the idea of of&shy;fer&shy;ing the first fruits 
                    (<a class="white-link" target="_blank"  rel="noopener" href="https://www.biblegateway.com/passage/?search=Leviticus+2%3A12&version=NKJV">Leviticus 2:12</a>) 
                    giv&shy;ing must be done trust&shy;ing God will pro&shy;vide 
                    (<a class="white-link" target="_blank"  rel="noopener" href="https://www.biblegateway.com/passage/?search=Proverbs+3%3A9-10&version=NKJV">Proverbs 3:9-10</a>).
                    The tithe was for the sup&shy;port of the Tab&shy;ern&shy;acle, and la&shy;ter, the Tem&shy;ple and the work of min&shy;is&shy;try. 
                    It was also for the up&shy;keep and re&shy;pair of the tem&shy;ple 
                    (<a class="white-link" target="_blank"  rel="noopener" href="https://www.biblegateway.com/passage/?search=Nehemiah+10%3A38&version=NKJV">Nehemiah 10:38</a>, 
                    <a class="white-link" target="_blank"  rel="noopener" href="https://www.biblegateway.com/passage/?search=Haggai+1%3A4-11&version=NKJV">Haggai 1:4-11</a>). 
                    It was also used to meet the needs of for&shy;eign&shy;ers, orph&shy;ans and wid&shy;ows 
                    (<a class="white-link" target="_blank"  rel="noopener" href="https://www.biblegateway.com/passage/?search=Deuteronomy+26%3A12-13&version=NKJV">Deuteronomy 26:12-13</a>). 
                </p>
                <p class="white-text"> 
                    Now since the New Test&shy;am&shy;ent builds on and clar&shy;if&shy;ies the Old Test&shy;am&shy;ent, what does our un&shy;change&shy;able God 
                    (<a class="white-link" target="_blank"  rel="noopener" href="https://www.biblegateway.com/passage/?search=Malachi+3%3A6&version=NKJV">Malachi 3:6</a>) 
                    de&shy;sire of us now as His church? In the New Test&shy;a&shy;ment we still see the com&shy;mand to sup&shy;port the min&shy;is&shy;try of the word of God 
                    (<a class="white-link" target="_blank"  rel="noopener" href="https://www.biblegateway.com/passage/?search=1+Timothy+5%3A18&version=NKJV">1&nbsp;Timothy 5:18</a>), 
                    widows 
                    (<a class="white-link" target="_blank" rel="noopener" href="https://www.biblegateway.com/passage/?search=1+Timothy+5%3A9-10&version=NKJV">1&nbsp;Timothy 5:9-10</a>) 
                    and the poor 
                    (<a class="white-link" target="_blank"  rel="noopener" href="https://www.biblegateway.com/passage/?search=Romans+15%3A26&version=NKJV">Romans 15:26</a>). 
                    While Christ ful&shy;fills the Law of Moses, we still are to obey the moral law out of thank&shy;ful&shy;ness. No&shy;where do we see a 
                    com&shy;mand that tith&shy;ing is done. Christ even prais&shy;es the wid&shy;ow who gave her two mites 
                    (<a class="white-link" target="_blank"  rel="noopener" href="https://www.biblegateway.com/passage/?search=Luke+21%3A3-4&version=NKJV">Luke 21:3-4</a>). 
                    Why? Because of her heart at&shy;ti&shy;tude! Now that Christ, not with sil&shy;ver or gold but His pre&shy;cious blood has 
                    re&shy;deemed us, we must now look at giv&shy;ing back to the Lord with a new att&shy;it&shy;ude! <strong>"Not grudgingly or of necessity; for God loves a cheerful giver"</strong> 
                    (<a class="white-link" target="_blank"  rel="noopener" href="https://www.biblegateway.com/passage/?search=2+Corinthians+9%3A7&version=NKJV">2&nbsp;Corinthians 9:7</a>). 
                    Int&shy;er&shy;est&shy;ing&shy;ly enough, if God puts the burd&shy;en on a heart, be&shy;cause of abil&shy;ity 
                    (<a class="white-link" target="_blank"  rel="noopener" href="https://www.biblegateway.com/passage/?search=1+Corinthians+16%3A2&version=NKJV">1&nbsp;Corinthians 16:2</a>, 
                    <a class="white-link" target="_blank"  rel="noopener" href="https://www.biblegateway.com/passage/?search=1+Peter+4%3A10&version=NKJV">1&nbsp;Peter 4:10</a>), 
                    the New Test&shy;am&shy;ent even ex&shy;pands the Old Test&shy;am&shy;ent com&shy;mand tell&shy;ing us to give in lib&shy;er&shy;al&shy;ity 
                    (<a class="white-link" target="_blank"  rel="noopener" href="https://www.biblegateway.com/passage/?search=Romans+12%3A8&version=NKJV">Romans 12:8</a>) 
                    know&shy;ing <strong>"it is more blessed to give than re&shy;ceive"</strong> 
                    (<a class="white-link" target="_blank"  rel="noopener" href="https://www.biblegateway.com/passage/?search=Acts+20%3A35&version=NKJV">Acts 20:35</a>). 
                    Our pur&shy;pose now, with e&shy;ter&shy;nal life in view, is to be rich to God stor&shy;ing up treas&shy;ures for the life to come 
                    (<a class="white-link" target="_blank"  rel="noopener" href="https://www.biblegateway.com/passage/?search=1+Timothy+6%3A17-19&version=NKJV">1&nbsp;Timothy 6:17-19</a>). 
                </p>
            </div>
        </div>
    </div>
</div>
